import { Link } from 'react-router-dom';
import Testimonial from '../components/testimonial';
import { Helmet } from 'react-helmet'; 
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import React, { useEffect } from 'react';
function Digital(){
  const testimonialProps1 = {
    title: "Virtual Assistant- Social Media & Internet Agency",
    imageSrc: "images/dm_a1.png",
    testimonialText: "The experience that I had with Uniwork Technologies was wonderful. They provided exceptional work, which they completed in a timely manner. They did an excellent job at responding, understanding exactly what I needed from them.",
  };

  const testimonialProps2 = {
    title: "Need Internet Marketing Guru for IG, FB, Web, Influencers - Specializing in Kitchen/Cooking Niche",
    imageSrc: "images/dm_a2.png",
    testimonialText: "Uniwork Technologies was supreme. They did a fantastic job of understanding our business and getting to the heart of what we really needed. Their expertise was evident from minute one. Not only did they make some excellent connections I'd never thought of before, they were so efficient in making everything happen. They  has my highest recommendation for your company's needs!",
  };

  const testimonialProps3 = {
    title: "Looking for subject matter experts to complete a personality rating survey",
    imageSrc: "images/dm_a3.png",
    testimonialText: "Eric was good and fast in completing the task given. We appreciate his efficiency and reliability for our company's needs.",
  };
  const testimonialProps4 = {
    title: "Brand Strategist for CPG Beauty Brand",
    imageSrc: "images/dm_a4.png",
    testimonialText: "Uniwork Technologies has been a great asset to our company! They helped us build out a brand strategy and are very knowledgeable about the DTC space. Highly recommended!",
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Ensure the page starts at the top when the component mounts.
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smoothly scroll to the top
  };

    return(
        <div className="mainn">
             <Helmet>
        <title>
        Uniwork Technologies: Tailored Digital Marketing Services</title>
<meta name='description' content='Get noticed online with our Digital Marketing Services. Our agency offers SEO, SMM, SMO, PPC, Web Analytics, & more. Contact Uniwork Technologies now.' />
      </Helmet>
            <section style={{ paddingTop : '1px' }}>
<div className="container-fluid">
<Fade up>
     <div className="websec_1 servcemx_wdth2 sec_3  mt-90 text-center pb-40">
          <h1>Digital <span style={{ color: '#223464' }}> Marketing</span> </h1>
<p>With a focus on excellence, We specialise in delivering a comprehensive suite of Digital Marketing Services tailored to boost your online presence and drive business growth. Our experienced team crafts customized solutions to meet your unique business goals, prioritizing transparent communication, continuous optimization, and affordability to ensure your Digital Marketing campaigns not only deliver exceptional results but also align seamlessly with your brand's identity and aspirations. We offer a wide range of solutions, including <Link to="/seo">Search Engine Optimization (SEO)</Link>, <Link to="/ppc">Pay-Per-Click (PPC)</Link> Advertising, website and <Link to="/website-analytics">Data Analytics</Link>, Social Media Marketing (SMM), Search Engine Marketing (SEM), Email Marketing, Conversion Rate Optimization (CRO), and more. Choose our best Digital Marketing Agency today to unlock the full potential of our Digital Marketing Services and thrive in the digital landscape. </p>
</div>
</Fade>
</div>
</section>            
<section>
<div className="pb-90"> 
  <img src="images/digital.webp" width="100%" alt="Top Digital Marketing Services"/>
</div>
</section>
<section>
	<div className="container-fluid">
     <div className=" websec_3 sec_3  mt-3 text-center ">
                <h2>Our<span style={{ color: '#223464' }}> Digital Marketing Services</span> </h2>

<div className="container mt-5">
  <div className="row">   
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 mb-5">
    <Zoom>
      <div className="box_1 seobox_3" style={{ backgroundColor: '#F2F1F1' }} >
      	<img src="images/Website-&-Data-Analytics.png" width="90" alt="Website & Data Analytics"/>
        <h3>Website & Data Analytics</h3>
        <p>Website and Data Analytics services can help you elevate your web presence. These services provide comprehensive Data Analytics that can help you gain insights into website traffic, user behavior, and key performance indicators. This information can help you make data-driven decisions that will enhance your digital presence.</p>
      </div>
      </Zoom>
          </div>    
   
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 mb-5">
    <Zoom>
      <div className="box_1" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Pay-Per-Click-Services.png" width="90" alt="PPC Management Services"/>
        <h3>Pay-Per-Click (PPC) Services</h3>
        <p>PPC Management services can help you reach your business goals. A team will work with you to create a tailored plan, manage your campaigns, and track your results. Ads will be targeted to the right people so you can get the most out of your investment.</p>
      </div>
      </Zoom>
    </div>    
   
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 mb-5">
    <Zoom>
      <div className="box_1 grphicbox_3" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Search-Engine-Optimization-(SEO).png" width="90" alt="Best SEO Services"/>
        <h3>Search Engine Optimization (SEO)</h3>
        <p>Improve your website's visibility and drive organic traffic with our SEO services. Our experts will optimize your website for relevant keywords, improve your backlinks, and track your results to ensure they are aligned with your business goals.</p>
      </div>
      </Zoom>
    </div>   
    
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 mb-5">
    <Zoom>
      <div className="box_1 grphicbox_3" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Social-Media-Marketing-(SMM).png" width="90" alt="Best SEO Services"/>
        <h3>Social Media Marketing (SMM)</h3>
        <p>Reach your target audience and achieve your business goals with our Social Media Marketing services. Our experts will create tailored strategies, optimize your content, and track your performance across social media platforms. Unlock the full potential of social media for your brand.</p>
      </div>
      </Zoom>
    </div>  
    
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 mb-5">
    <Zoom>
      <div className="box_1 grphicbox_3" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Search-Engine-Marketing.png" width="90" alt="Best SEO Services"/>
        <h3>Search Engine Marketing (SEM)</h3>
        <p>We specialize in helping businesses increase their online visibility and generate targeted traffic. In today's competitive digital landscape, a strong search engine presence is essential for success. Our Search Engine Marketing (SEM) services can help you achieve this by optimizing your website for search engines and driving relevant traffic to your site.</p>
      </div>
      </Zoom>
    </div>   
    
    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 mb-5">
    <Zoom>
      <div className="box_1 grphicbox_3" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Conversion-Rate-Optimization-(CRO).png" width="90" alt="Best SEO Services"/>
        <h3>Conversion Rate Optimization (CRO)</h3>
        <p>Increase your website's conversion rate with our Conversion Rate Optimization (CRO) services. Our experts will work with you to identify and fix areas of your website that are preventing visitors from converting into customers. We'll help you boost customer engagement and drive revenue growth.</p>
      </div>
      </Zoom>
    </div>    
  </div>
  <div className="ttl_ss_des"><p>Ready to improve your online presence and grow your business? <Link to="/">Uniwork Technologies</Link> is renowned as the ultimate all-in-one <Link to="/digital-marketing-agency" onClick={scrollToTop}>Digital Marketing Agency</Link> to make it happen. Contact us today, and let's elevate your online presence and explore more services such as <Link to="/web-development">Web Development</Link>, Graphic Design, <Link to="/web-design">Web Designing,</Link> and more. Let's take your digital journey to the next level together.</p></div>
</div>


            </div>
			</div>
</section>
<section className="bg-light services_callsec mt-90">
      <div className="container">
        <div className="row align-items-center">  
          <div className="col-md-6">
            <div>
              <h2>Schedule a call</h2>
              <h2>now with our</h2>
              <h2>Digital Marketing</h2>
              <h2>expert!</h2>
            </div>
            <div className='btn_areaservsec'>
              <img src='images/right_arrow.png'/>
              <button className="btn_services shed_call">
      <Link to="https://wa.me/06239452423" target='_blank'>Contact Us</Link>
    </button>
            </div>
          </div>
          <div className="col-md-6">
            {/* <img src="images/smiling-woman-headset-presentation-something.png" alt="Background Image" className="img-fluid" /> */}
          </div>
        </div>
      </div>
    </section>
    <div>
    <section className="mt-90 pb-90 abt_last7 mx-4" aria-label='Ut back'>
<div className="mx_wdth2 sec_3 mt-90 pb-90 text-center">
<p className="svr_12 pt-3">- TESTIMONIAL -</p>
<h2>See What People Say<span style={{ color: '#223464' }}> About Us</span></h2>
</div>
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner abt-tsti1">
          <div className="carousel-item active p-4">
            <Testimonial {...testimonialProps1} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps2} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps3} />
          </div>  
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps4} />
          </div>            
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"><img src="images/lft.webp" alt="Breadcrumb Left"/></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"><img src="images/rgt.webp" alt="Breadcrumb Right"/></span>
      <span className="visually-hidden">Next</span>
    </button>
      </div>
      </section>
      </div>
            
            </div>
    );
}

export default Digital;
