import React, { useRef, useState } from "react";

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; 
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import Zoom from 'react-reveal/Zoom';
function Contact(){
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const recaptchaRef = useRef();

  const sendEmail = (e) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    e.preventDefault();
    if (recaptchaValue) {
    emailjs
      .sendForm(
        "service_7lt5caa",
        "template_0s4kzog",
        form.current,
        "JHN9VYGaCabt3cD9H"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSuccess(true);
          // Clear the form by resetting it
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
    } else {
      alert("Please verify that you are not a robot!");
    }
  };

  const handleRecaptchaChange = (value) => {
    // Handle the change of reCAPTCHA value
    console.log("Captcha value:", value);
  };


  
    return(
        <div>
          <Helmet>
        <title>
        Contact Us | Uniwork Technologies</title>
<meta name='description' content='Ready to elevate your business online presence? Uniwork Technologies offers innovative web design and digital marketing services. Reach out to us today.' />
      </Helmet>
            <div className="prtfliosec_1" aria-label='Contact Us- Uniwork Technologies'> 
    <div className="bnrtxt_1 container-fluid prt1bnrtxt_1">
<h1><span style={{ color: '#fff' }}>Contact Us</span></h1>
    </div>


    </div>

    <div className="container-fluid mt-5">
  <div className="row clonta_inffma text-center">
    <div className="col-sm-6">
      <Zoom>
      <div className=" cnt_1" style={{ backgroundColor: '#F2F1F1' }}>
      	<img className="pb-3" src="images/cnt1.webp" width="38" alt="Location" />
       <h2>Our Address</h2>
        <p>D-151, Phase-8, Industrial Area, Sector 71,<br></br> Sahibzada Ajit Singh Nagar, Punjab 160071</p>
      </div>
      </Zoom>
    </div>
    <div className="col-sm-3">
      <Zoom>
      <div className="cnt_1 cnt_2" style={{ backgroundColor: '#F2F1F1', height: '100%' }}>
      	<Link to="#"><img className="pb-3" src="images/cnt2.webp" width="38" alt="Call Us" /></Link>
        <h2>Call US</h2>
        <Link to="tel:+916239452423"><p>+91 6239452423</p></Link>
      </div>
      </Zoom>
    </div>
    <div className="col-sm-3">
      <Zoom>
      <div className=" cnt_1 cnt_2 cnt_3" style={{ backgroundColor: '#F2F1F1', height: '100%' }}>
      	<Link to="mailto:uniworkitsolution@gmail.com"><img className="pb-3" src="images/cnt3.webp" width="38" alt="Email Us" /></Link>
        <h2>E-mail Us</h2>
        <Link to="mailto:uniworkitsolution@gmail.com"><p>uniworkitsolution@gmail.com</p></Link>
      </div>
      </Zoom>
    </div>
  </div>
</div>

    <div className="container-fluid mt-5 pb-5">
      <div className="row">
        <div className="col-sm-6">
        <iframe
          title="Uniwork Technologies Location"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.924242451193!2d76.6960059!3d30.7170245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef11b97b6521%3A0x7fd29a19c79fa02c!2suniwork%20technologies!5e0!3m2!1sen!2sus!4v1627076078997!5m2!1sen!2sus"
        ></iframe>
        </div>
        <div className="col-sm-6">
          <div style={{ backgroundColor: '#F2F1F1', height: '100%' }}>       

          <form className="cntctfom_12" ref={form} onSubmit={sendEmail}>
              <div className="d1_1">
                <input type="text" placeholder="Your Name" id="name" name="to_name" required />
              </div>
              <div className="d1_2">
                <input type="email" placeholder="E-mail" id="email" name="email" required />
              </div>
              <div className="d1_2">
              <input type="tel" placeholder="Phone Number" id="phone" required name="phonenumber" />
              </div>
              <div className="d1_1">
                <input type="text" placeholder="Subject" id="subject" name="subject" required />
              </div>
              <div>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Message"
                  rows="4"
                  required
                ></textarea>
              </div>

              <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LfRtAQpAAAAAGF9-5Xzk6narmJKxfmtCOXCzSax"
          onChange={handleRecaptchaChange}
        />

              <div className="cntct_btn1">
                <input type="submit" id="contactpage_submitbtn" value="Send Message" />
              </div>
              {isSuccess && <p id="success_full121">Message sent successfully!</p>}
            </form>            
          </div>
        </div>
      </div>
    </div>

        </div>
    );
}

export default Contact;