import Testimonial from '../components/testimonial';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; 
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { useEffect } from 'react';
function Seo(){
  const testimonialProps1 = {
    title: "Online SEO For Multiple Construction Websites",
    imageSrc: "images/se_o1.png",
    testimonialText: "Eric is a very good contractor. He goes above and beyond with expectations.",
  };

  const testimonialProps2 = {
    title: "SEO Agency to analyze existing data to suggest SEO optimazing",
    imageSrc: "images/se_o2.png",
    testimonialText: "Uniwork and their team come highly recommended for our company. They are not only very friendly but also exceptionally skilled, delivering work that precisely aligns with our briefing. We are impressed with their work.",
  };

  const testimonialProps3 = {
    title: "SEO Audit and Execution plan to solve our issues",
    imageSrc: "images/se_o3.png",
    testimonialText: "Working with Eric was a pleasure. They are exceptional professionals with a deep understanding of SEO. Both the communication and the project management by his team were outstanding. They helped us improve our SEO rankings through a detailed SEO audit of our website and an action plan with clear instructions.",
  };
  const testimonialProps4 = {
    title: "Full technical SEO audit.",
    imageSrc: "images/se_o4.png",
    testimonialText: "We required a full audit of our site, along with keyword research and advice on moving forward with link building, and Uniwork Technologies' expertise in all aspects of SEO is top-notch. The full audit revealed key insights we hadn't realized. We would eagerly hire them again for future projects.",
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Ensure the page starts at the top when the component mounts.
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smoothly scroll to the top
  };

    return(
        <div className="mainn">
 <Helmet>
        <title>
        Comprehensive On-Page, Off-Page, & Technical SEO Services</title>
<meta name='description' content='Maximize online excellence with our expert search engine optimization services. Boost traffic & rankings through On-page, Off-page, & technical SEO expertise.' />
      </Helmet>
<section style={{ paddingTop : '1px' }}>
<div className="container-fluid">
  <Fade up>
     <div className="websec_1 servcemx_wdth2 sec_3  mt-90 text-center pb-40">
                <h1>Search Engine<span style={{ color: '#223464' }}>  Optimization</span> </h1>
<p>Experience the excellence of our SEO agency. We recognize the vital importance of search engine optimization (SEO) in ensuring the success of your online business. In an ever-evolving digital landscape, having a strong online presence is more crucial than ever. By staying well-informed about the latest SEO techniques and strategies, our team of experts ensures that our clients' websites are thoroughly optimized for search engines. With our premium SEO services, we aim to help you attain increased visibility, higher traffic, and lasting growth in the fiercely competitive online marketplace.
 </p>
</div>
</Fade>
</div>
</section>

<section>
<div className="pb-90"> 
  <img src="images/seobnr.webp" width="100%" />
</div>
</section>

<section>
<div className="container-fluid">
     <div className=" websec_3 sec_3  mt-3 text-center ">
                <h2>Our<span style={{ color: '#223464' }}> Search Engine Optimization Services</span> </h2>

<div className="container mt-5 sco_ct">
  <div className="row mb-5">
    <div className="col">
      <Zoom>
      <div className="box_1 seobox_3" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/seo1.webp" width="90" alt="Keyword Research and Analysis - Search Engine Optimization Services" />
        <h3>Keyword Research and Analysis</h3>
        <p>We begin by conducting comprehensive keyword research to identify the terms and phrases that resonate with your target audience. Our data-driven approach ensures that we target the most relevant keywords for effective SEO optimization.
</p>
      </div>
      </Zoom>
    </div>
    <div className="col">
      <Zoom>
      <div className="box_1" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/seo2.webp" width="90" alt="On-Page SEO Services - SEO agency" />
        <h3>On-Page SEO Services</h3>
        <p>Our experts optimize every aspect of your website, including meta tags, content, images, and site structure. This On-page SEO optimization ensures that search engines can easily crawl and index your site, leading to improved rankings.
</p>
      </div>
      </Zoom>
    </div>
    <div className="col">
      <Zoom>
      <div className="box_1 " style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Off-Page-SEO-Services.png" width="90" alt="Off-Page SEO Services - Search Engine Optimization (SEO) Services" />
        <h3>Off-Page SEO Services</h3>
        <p>Building authoritative backlinks is a key aspect of our SEO strategy. We employ white-hat techniques for Off-page SEO to acquire high-quality backlinks from reputable sources, increasing your website's authority and credibility.</p>
      </div>
      </Zoom>
    </div>
  </div>
  <div className="row">
    <div className="col">
      <Zoom>
      <div className="box_1 seobox_3" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Local-SEO-Services.png" width="90" alt="Local SEO Services - SEO services" />
        <h3>Local SEO Services</h3>
        <p>If you have a brick-and-mortar business or serve a specific geographic area, our best local SEO services can help you dominate local search results. We optimize your online presence for local SEO, ensuring your business is visible to local customers when they need your products or services.</p>
      </div>
      </Zoom>
    </div>
    <div className="col">
      <Zoom>
      <div className="box_1" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Technical-SEO-Services.png" width="90" alt="Technical SEO Services - SEO agency Services" />
        <h3>Technical SEO Services</h3>
        <p>We conduct a thorough audit of your website's technical aspects as part of our technical SEO services to identify and address any issues that may hinder your SEO efforts. This includes optimizing site speed, mobile-friendly tests, and other technical factors.</p>
      </div>
      </Zoom>
    </div>
    <div className="col">
      <Zoom>
      <div className="box_1 " style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Analytics-and-Reporting.png" width="90" alt="Analytics and Reporting - Search Engine Optimization Agency Services" />
        <h3>Analytics and Reporting</h3>
        <p>Transparency is at the core of our search engine optimization services. We deliver regular reports and <Link to="/website-analytics"> website analytics </Link> to keep you updated on your progress and enable data-driven decisions for continuous improvement.
</p>
      </div>
      </Zoom>
    </div>
  </div>
  <div className="ttl_ss_des"><p>Looking to strengthen your business's online visibility? Choose <Link to="/">Uniwork Technologies</Link> for <Link to="/seo" onClick={scrollToTop}>on-page and off-page SEO services</Link> and observe your website's ascent in search engine results. Contact us today for a consultation, and let's discuss how our <Link to="/digital-marketing-agency">digital marketing services</Link> can help you achieve your online marketing goals.

</p></div>
</div>


            </div>
			</div>
</section>
<section className="bg-light services_callsec mt-90">
      <div className="container">
        <div className="row align-items-center">  
          <div className="col-md-6">
            <div>
              <h2>Schedule a call</h2>
              <h2>now with our</h2>
              <h2>SEO</h2>
              <h2>expert!</h2>
            </div>
            <div className='btn_areaservsec'>
              <img src='images/right_arrow.png'/>
              <button className="btn_services shed_call">
      <Link to="https://wa.me/06239452423" target='_blank'>Contact Us</Link>
    </button>
            </div>
          </div>
          <div className="col-md-6">
            {/* <img src="images/smiling-woman-headset-presentation-something.png" alt="Background Image" className="img-fluid" /> */}
          </div>
        </div>
      </div>
    </section>
    <div>
    <section className="mt-90 pb-90 abt_last7 mx-4" aria-label='Ut back'>
<div className="mx_wdth2 sec_3 mt-90 pb-90 text-center">
<p className="svr_12 pt-3">- TESTIMONIAL -</p>
<h2>See What People Say<span style={{ color: '#223464' }}> About Us</span></h2>
</div>
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner abt-tsti1">
          <div className="carousel-item active p-4">
            <Testimonial {...testimonialProps1} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps2} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps3} />
          </div>  
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps4} />
          </div>           
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"><img src="images/lft.webp" alt="Breadcrumb Left"/></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"><img src="images/rgt.webp" alt="Breadcrumb Right"/></span>
      <span className="visually-hidden">Next</span>
    </button>
      </div>
      </section>
      </div>
        </div>
    );
}

export default Seo;