import React from 'react';

function Testimonial(props) {
  const { title, imageSrc, testimonialText } = props;

  return (
    <div>
      <p>{title}</p>
      <img src={imageSrc} width="30%" alt={title} />
      <p style={{ fontStyle: 'italic' }}>{testimonialText}</p>
    </div>
  );
}

export default Testimonial;
