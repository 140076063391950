import Testimonial from '../components/testimonial';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; 
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import React, { useEffect } from 'react';
function Template(){
  const testimonialProps1 = {
    title: "Need current PDF report updated into a Basic Microsoft Word Template",
    imageSrc: "images/tm_p1.png",
    testimonialText: "Uniwork Technologies is extremely easy to work with and very accommodating. They are clear, concise, and highly efficient. We hope to work with them again in the very near future for our company.",
  };

  const testimonialProps2 = {
    title: "Create a Functional Master PowerPoint Template",
    imageSrc: "images/tm_p2.png",
    testimonialText: "I would 100% recommend Uniwork Technologies to anyone needing PowerPoint support for our company. Their professionalism, patience, and expertise in PowerPoint allowed them to successfully deliver on this project. Diksha asked all the right questions to provide expert guidance and user-friendly recommendations, which elevated our corporate template.",
  };

  const testimonialProps3 = {
    title: "Create Banking Proposal Template in MS Word",
    imageSrc: "images/tm_p3.png",
    testimonialText: "Uniwork Technologies was excellent for our company, completing our MS Word proposal formatting and design with perfect attention to detail. They were a pleasure to work with, showing excellent communication skills and speedy work, despite living in opposite time zones!",
  };

  const testimonialProps4 = {
    title: "URGENT TODAYCreating a word document template",
    imageSrc: "images/tm_p4.png",
    testimonialText: "Uniwork Technologies was of great help for our company, working with very tight deadlines. We highly recommend them for all work produced in Word. Their templates were faultless and matched our designs expertly.",
  };
    useEffect(() => {
      window.scrollTo(0, 0); // Ensure the page starts at the top when the component mounts.
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Smoothly scroll to the top
    };
    return(
        <div className="mainn">
 <Helmet>
        <title>
        Explore Stunning Website Templates & Customization Services</title>
<meta name='description' content='Choose Uniwork Technologies for website template services that enable you to customize your online presence effortlessly. Your perfect web template awaits.' />
      </Helmet>
<section style={{ paddingTop: '1px' }}>
<div className="container-fluid">
  <Fade up>
     <div className="websec_1 servcemx_wdth2 sec_3  mt-90 text-center pb-40 ">
                <h1>Template<span style={{ color: '#223464' }}></span> </h1>
<p>Our team of experienced designers and developers work closely with clients to create Customized Templates that not only look great but also drive engagement and conversions. We understand that every business has unique needs, which is why we offer a wide range of Template options to choose from or can create a completely custom design tailored to your specific requirements. Our Templates are optimized for all devices and email clients, ensuring that your messages always look professional and polished. With our Email Template services, you can streamline your communication efforts and take your Email Marketing to the next level. </p>
</div>
</Fade>
</div>
</section>	

<section>
<div className="tempsec_2 pb-90">
 
 <img src="images/template123.webp" width="100%" alt="Our Website Template Services" />

</div>
</section>

<section>
<div className="container-fluid">
     <div className=" websec_3 sec_3  text-center ">
                <h2>Our<span style={{ color: '#223464' }}> Template Services</span> </h2>

<div className="container mt-5">
  <div className="row">
    <Zoom>
    <div className="col">
      <div className="box_1 " style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/temp1.webp" width="90" alt="Custom-Crafted Templates" />
       <h3>Custom-Crafted Templates</h3>
        <p>Our designers create Bespoke Templates that reflect your brand's unique identity and goals. We optimize for user-friendliness and visual appeal, ensuring that your website stands out from the competition.</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
    <div className="col">
      <div className="box_1 " style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/temp2.webp" width="90" alt="Responsive Design" />
        <h3>Responsive Design</h3>
        <p>Our templates are designed to be fully Responsive, meaning that they look great on any device. This not only improves your website's User Experience, but also boosts your search engine rankings.</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
    <div className="col">
      <div className="box_1 paybox_3 " style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/temp3.webp" width="90" alt="Time And Cost Savings" />
        <h3>Time and Cost Savings</h3>
        <p>With our Customized Templates, you can save both time and money on your website design. We ensure that your Template is optimized for speed and performance, which reduces the need for ongoing maintenance and updates.</p>
      </div>
    </div>
    </Zoom>
  </div>
  <div className="ttl_ss_des"><p>Looking for the perfect <Link to="/website-template" onClick={scrollToTop}>Website Template</Link> to express your brand's unique identity and engage your target audience? Your search ends here with <Link to="/">Uniwork Technologies</Link>! Our wide range of templates caters to various industries and designs, simplifying online presence creation. In addition to our templates, we offer services in <Link to="/web-development">Web Development</Link>, <Link to="/graphic-design">Graphic Designing,</Link> and <Link to="/web-design">Website Design</Link>, ensuring you have a comprehensive solution. Begin your journey to a remarkable online presence—explore our selection and services now!
</p></div>
</div>


            </div>
			</div>
</section>			
<section className="bg-light services_callsec mt-90">
      <div className="container">
        <div className="row align-items-center">  
          <div className="col-md-6">
            <div>
              <h2>Schedule a call</h2>
              <h2>now with our</h2>
              <h2>Website templates</h2>
              <h2>expert!</h2>
            </div>
            <div className='btn_areaservsec'>
              <img src='images/right_arrow.png'/>
              <button className="btn_services shed_call">
      <Link to="https://wa.me/06239452423" target='_blank'>Contact Us</Link>
    </button>
            </div>
          </div>
          <div className="col-md-6">
            {/* <img src="images/smiling-woman-headset-presentation-something.png" alt="Background Image" className="img-fluid" /> */}
          </div>
        </div>
      </div>
    </section>
    <div>
    <section className="mt-90 pb-90 abt_last7 mx-4" aria-label='Ut back'>
<div className="mx_wdth2 sec_3 mt-90 pb-90 text-center">
<p className="svr_12 pt-3">- TESTIMONIAL -</p>
<h2>See What People Say<span style={{ color: '#223464' }}> About Us</span></h2>
</div>
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner abt-tsti1">
          <div className="carousel-item active p-4">
            <Testimonial {...testimonialProps1} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps2} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps3} />
          </div>  
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps4} />
          </div>         
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"><img src="images/lft.webp" alt="Breadcrumb Left"/></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"><img src="images/rgt.webp" alt="Breadcrumb Right"/></span>
      <span className="visually-hidden">Next</span>
    </button>
      </div>
      </section>
      </div>
   
          </div>
    );
}

export default Template;