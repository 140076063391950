import React from 'react';
import { Helmet } from 'react-helmet';

const Layout = ({ children }) => {
  const canonicalUrl = "https://www.uniworktechnologies.com" + window.location.pathname;

  return (
    <div> 
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      {children}
    </div>
  );
};

export default Layout;
