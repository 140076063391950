import React, { useRef, useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from 'react-helmet'; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function Marketing() {
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const recaptchaRef = useRef();

  const sendEmail = (e) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    e.preventDefault();
    if (recaptchaValue) {
    emailjs
      .sendForm(
        "service_7lt5caa",
        "template_xzx0gm2",
        form.current,
        "JHN9VYGaCabt3cD9H"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSuccess(true);
          // Clear the form by resetting it
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
    } else {
      alert("Please verify that you are not a robot!");
    }
  };

  const handleRecaptchaChange = (value) => {
    // Handle the change of reCAPTCHA value
    console.log("Captcha value:", value);
  };


  useEffect(() => {
    window.scrollTo(0, 0); // Ensure the page starts at the top when the component mounts.
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smoothly scroll to the top
  };
  // const slidesData = [
  //   "Increase Visibility",
  //   "Boost Sales",
  //   "Capture Attention",
  // ];

  // const [currentIndex, setCurrentIndex] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % slidesData.length);
  //   }, 2000); // Adjust the interval time (ms) to control the slideshow speed

  //   return () => clearInterval(interval);
  // }, [slidesData.length]);
  return (
    <>
       <Helmet>
    <Link to="https://fonts.googleapis.com/css2?family=Inter+Slab&family=Inter:wght@400;500;700;800;900&display=swap" rel="stylesheet"></Link>
        <title>
        On-Page SEO & Technical SEO Services for Website Excellence</title>
<meta name="description" content="Improve your website's visibility with our on-page SEO & technical SEO services. Our SEO experts optimize every page to target the right audience for your site." />
      </Helmet>
   <header className="no_pg_anmhdr">
  <div className="container-fluid no_pg_anm">
      <div className="logo">
        <Link className="navbar-brand" to="#" onClick={scrollToTop}><img src="Images/logo.png" alt="Uniwork Technologies Logo (landscape)" /></Link>
      </div>
      <div className="connect-button">
        <Link className="lts_connectbtn" to="/on-page-seo" onClick={scrollToTop}>Let's Connect</Link>
      </div>
  </div>
</header>

      <section className="bnrdg_2">
        <div className="container-fluid">
          <div className="row pb-5 align-items-center">
            <div className="col-sm-6 mt-4 pb-2">
              <h1 className="hd_bnr1">On-Page SEO & Technical SEO <br></br>Services for Website Optimization</h1>
              {/* <div className="slide-container">
              {slidesData.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentIndex ? 'active' : ''}`}
            style={{ color: '#223464' }}
          >
            {slide}
          </div>
        ))}
              </div> */}
              {/* <h2 className="uniwk_2">With Uniwork Technologies</h2> */}
              <p className="pwr_1">Elevating Your Business to New Heights</p>
              <p className="dtful_1">Want to enhance your website's performance for increased traffic and business growth? Uniwork Technologies offers On-Page SEO, including SEO audits, technical SEO, and detailed analysis. To get started, click on the Contact Us button to reach out!</p>
            </div>

            <div className="col-sm-6 fmbx_1">
              <div className="container">
              <form id="digtlfom_13" className="digtlfom_1" ref={form} onSubmit={sendEmail}>
      <h3>Let's Work Together</h3>
      <p className="fil_1">*Please fill out all fields.</p>
      <div className="upr_1">
        <div className="onbr">
          <label htmlFor="fname">First Name*</label>
          <input type="text" id="fname" required name="first_name" placeholder="" />
        </div>
        <div className="onbr">
          <label htmlFor="lname">Last Name</label>
          <input type="text" id="lname" name="last_name" placeholder="" />
        </div>
      </div>

      <div className="upr_1 upr_2">
        <div className="onbr">
          <label htmlFor="email">Email*</label>
          <input type="email" id="email" required name="email" />
        </div>
        <div className="onbr">
          <label htmlFor="phone">Phone Number*</label>
          <input type="tel" id="phone" required name="ph_number" />
        </div>
      </div>

      <label htmlFor="Services">Services</label>
      <div className="rdbtn_1">
        <div className="rdflx_1">
          <div className="rdist_1">
            <input type="checkbox" id="WebsiteDataAnalytics" name="user_sel_services_one" value="On-Page SEO Optimization" />
            <label htmlFor="WebsiteDataAnalytics">On-Page SEO Optimization</label>
          </div>
          <div className="rdist_1">
            <input type="checkbox" id="SocialMediaMarketing" name="user_sel_services_two" value="Technical SEO Services" />
            <label htmlFor="SocialMediaMarketing">Technical SEO</label>
          </div>
        </div>

        {/* <div className="rdflx_1">
          <div className="rdist_1">
            <input type="radio" id="PPCMarketing" name="user_sel_services" required />
            <label htmlFor="PPCMarketing">PPC Marketing</label>
          </div>
          <div className="rdist_1">
            <input type="radio" id="SearchEngineOptimization" name="user_sel_services" required />
            <label htmlFor="SearchEngineOptimization">Search Engine Optimization</label>
          </div>
        </div>

        <div className="rdflx_1">
          <div className="rdist_1">
            <input type="radio" id="SearchEngineMarketing" name="user_sel_services" required />
            <label htmlFor="SearchEngineMarketing">Search Engine Marketing</label>
          </div>
          <div className="rdist_1">
            <input type="radio" id="ConversionRateOptimization" name="user_sel_services" required />
            <label htmlFor="ConversionRateOptimization">Conversion Rate Optimization</label>
          </div>
        </div> */}
      </div>

      <label htmlFor="Message">Message</label>
      <textarea id="Message" name="message" placeholder="" style={{ height: '200px' }} />

      <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LfRtAQpAAAAAGF9-5Xzk6narmJKxfmtCOXCzSax"
          onChange={handleRecaptchaChange}
        />


      <div className="btnfomdgt_1">
        <input type="submit" id="onpage_submitbtn" value="Let Us Hear From You" />
      </div>
      {isSuccess && <p className="message_sent_sf">Message sent successfully!</p>}
    </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row mt-90 align-items-center">
            <div className="col-sm-6 dscvr_1">
              <h2>Boost Your Website's Visibility with Expert On-Page SEO & Technical SEO Services</h2>
              <p>Elevate your website's search engine rankings and online visibility with our tailored On-Page SEO and technical SEO services. We specialize in a diverse range of offerings aimed at enhancing your website's search engine performance and overall online presence, with a particular focus on On-Page SEO. Our expertise covers everything from in-depth On-Page SEO audits to meticulous analysis and fine-tuning for optimal visibility and user engagement. We provide a comprehensive suite of On-Page Search Engine Optimization services, which includes refining content, adjusting meta tags, conducting in-depth keyword research, and On-site optimization. Collaborate with us to harness the full potential of On-Page SEO and take your online visibility to new heights.</p>
            </div>
            <div className="col-sm-6 dscvr_21">
              <img src="images/On-Page-SEO-&-Technical-SEO.png" width="90%" alt="Boost Your Website's" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="websec_3 sec_3 mt-90 text-center ">
            <h2 className="srv_34">Our SEO Services: Tailored for Your Online Business Growth</h2>
            <div className="mt-5 text-start">
              <div className="row">
                <div className="col">
                  <div className="anlytc_12">
                    <img src="images/dl1.webp" alt="dl1" />
                    <h3>On-Page SEO Optimization</h3>
                    <p>Our On-Page SEO experts will optimize every aspect of your website to ensure it's search engine friendly:</p>
                    <ul className="blt_1">
                      <p>Key points:</p>
                      <li><strong style={{color: '#223464'}}>Keyword Research:</strong> We identify the best keywords for your industry and create content that ranks.</li>
                      <li><strong style={{color: '#223464'}}>Content Optimization:</strong> Engage and inform your visitors with high-quality, keyword-optimized content.</li>
                      <li><strong style={{color: '#223464'}}>Meta and Heading Tags:</strong> We fine-tune every element to maximize click-through rates.</li>
                      <li><strong style={{color: '#223464'}}>Image Optimization:</strong> Optimize images and alt text.</li>
                      <li><strong style={{color: '#223464'}}>Linking Strategy:</strong> Use Internal links for your site's pages and External links for reputable external websites.</li>
                      <li><strong style={{color: '#223464'}}>User Experience:</strong> Ensure that your website loads quickly and is mobile-responsive.</li>
                      <li><strong style={{color: '#223464'}}>Monitoring and Analytics:</strong> Use tools to track progress.</li>
                    </ul>
                  </div>
                </div>
               
                <div className="col">
                  <div className="anlytc_12">
                    <img src="images/dl3.webp" alt="dl3" />
                    <h3>Technical SEO Services</h3>
                    <p>When it comes to optimizing a website, our technical SEO specialist takes the lead, addressing the technical challenges to improve search engine rankings.:</p>
                    <ul className="blt_1">
                      <p>Key points:</p>
                      <li><strong style={{color: '#223464'}}>Website Speed and Performance:</strong> Lightning-fast loading times for a better user experience.</li>
                      <li><strong style={{color: '#223464'}}>Mobile Optimization:</strong> Ensure your site looks great and functions flawlessly on all devices.</li>
                      <li><strong style={{color: '#223464'}}>Crawlability and Indexing:</strong> We ensure search engine bots can easily crawl and index your website for better visibility.</li>
                      <li><strong style={{color: '#223464'}}>Security:</strong> Protect your site and user data with HTTPS and other security measures.</li>
                      <li><strong style={{color: '#223464'}}>Server and Hosting Considerations:</strong> Understanding server response times, hosting quality, and uptime as these can impact SEO.</li>
                      <li><strong style={{color: '#223464'}}>SEO Audits and Tools:</strong> We cover how to conduct technical SEO audits and utilize various tools to identify and address issues.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row mt-90 align-items-center">            
            <div className="col-sm-6 dscvr_21 r1f1">
              <img src="images/Tools-We-Use.png" width="90%" alt="Tools We Use" />
            </div>
            <div className="col-sm-6 dscvr_1">
              <h2>Tools We Use</h2>
              <p>At Uniwork Technologies, we leverage the power of industry-leading SEO tools to deliver top-notch results for our clients. Our toolkit includes:</p>
              <ul class="">
              <li><strong style={{color: '#223464'}}>Google Analytics:</strong> We harness the insights provided by Google Analytics to make data-driven decisions for your website's success.</li>
              <li><strong style={{color: '#223464'}}>Google Search Console:</strong> We monitor your site's presence in Google search results and fix technical issues to improve visibility.</li>
              <li><strong style={{color: '#223464'}}>SEMrush:</strong> We utilize SEMrush's robust features for competitor analysis, keyword research, and tracking your website's performance.</li>
              <li><strong style={{color: '#223464'}}>Ahrefs:</strong> Ahrefs helps us with backlink analysis, keyword research, and content exploration to enhance your SEO strategy.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row mt-90 align-items-center rxx_rev">
            <div className="col-sm-6 dscvr_1">
              <h2>What Sets Us Apart?</h2>
              <ul class="">
              <li><strong style={{color: '#223464'}}>Experienced Team:</strong> Our SEO experts have a proven track record of success.</li>
              <li><strong style={{color: '#223464'}}>Customized Strategies:</strong> We customize our strategies to your unique needs and goals.</li>
              <li><strong style={{color: '#223464'}}>Data-Driven:</strong> Our decisions are based on in-depth data analysis.</li>
              <li><strong style={{color: '#223464'}}>Continuous Monitoring:</strong> Your success is our priority, which is why we continuously fine-tune your site for enduring excellence.</li>
              </ul>
            </div>
            <div className="col-sm-6 dscvr_21">
              <img src="images/Sets-Us-Apart.png" width="90%" alt="What Sets Us Apart?" />
            </div>
          </div>
        </div>        
      </section>
      <div class="ttl_ss_des"><p>Secure your place at the top of search engine rankings with our On-Page SEO and Technical SEO services. Our dedicated team is here to answer any questions you may have and guide you on the path to SEO success. Contact us now to get started.</p></div>
      <section className="hpy_clint_sec">
        <div className="container-fluid mt-90 pb-5">
          <div className="row">
            <div className="titls_clnt pb-4 text-center">
              <h2 className="">Our Happy <span className="bordr_23">Clients</span></h2>
              <p>We believe in going the extra mile for our clients</p>
            </div>
            <div className="col-lg-3">
              <img className="hp_logo" src="images/Group-9845.webp" alt="logo" />
            </div>
            <div className="col-lg-9 crl_it">
              {/* Owl Carousel for the reviewSlider */}
              <OwlCarousel
                className="owl-theme"
                loop={true}
                nav={true}
                dots={false}
                items={3}
                responsive={{
                  0: {
                    items: 1,
                  },
                  768: {
                    items: 3,
                  },
                }}
              >
                {/* Review Slide 1 */}
                <div className="rt1_ro">
                  <div className="d-flex flex-column">
                    <span className="nmcrs_l">Louis Hancock.</span>
                    <img className="reviw_stars" src="images/stars.webp" alt="rating" />
                    <span className="dtrv_1">April 13, 2023 - June 28, 2023</span>
                  </div>
                  <div className="revews_cnt">
                    <p>The goal of this project was to find someone who is highly conscientious and detail oriented to do the hard work up implementing technical SEO improvements on the website... thankfully I found the perfect person with Eric. He is not only very detail oriented, but he makes fixes fast and presents the changes in an organized, easy to track format. I highly recommend him and will certainly be hiring him again in the future.</p>
                  </div>
                </div>

                {/* Review Slide 2 */}
                <div className="rt1_ro">
                  <div className="d-flex flex-column">
                    <span className="nmcrs_l">Amanda R.</span>
                    <img className="reviw_stars" src="images/stars.webp" alt="rating" />
                    <span className="dtrv_1">Feb 24, 2019 - Mar 29, 2020</span>
                  </div>
                  <div className="revews_cnt">
                    <p>We contracted with Uniwork for several months to handle on-page SEO, image optimization, page speed improvement, and pushing content live. They are great to work with, very proactive and experienced. Would definitely recommend them and work with them again when the need arises.</p>
                  </div>
                </div>

                {/* Review Slide 3 */}
                <div className="rt1_ro">
                  <div className="d-flex flex-column">
                    <span className="nmcrs_l">Shari A.</span>
                    <img className="reviw_stars" src="images/stars.webp" alt="rating" />
                    <span className="dtrv_1">April 23, 2019 - Mar 29, 2020</span>
                  </div>
                  <div className="revews_cnt">
                    <p>Eric is very knowledgeable about the SEO topic and how it works. Sets right expectations and talks clearly about the process of implementation. Recommend. He is always responsive, answers any questions, ready to work and responsible.</p>
                  </div>
                </div>

                {/* Review Slide 4 */}
                <div className="rt1_ro">
                  <div className="d-flex flex-column">
                    <span className="nmcrs_l">Valters L.</span>
                    <img className="reviw_stars" src="images/stars.webp" alt="rating" />
                    <span className="dtrv_1">May 27, 2022 - Apr 27, 2023</span>
                  </div>
                  <div className="revews_cnt">
                    <p>Working with Eric was a pleasure. They are exceptional professionals with a deep understanding of SEO. Both the communication and the project management by his team were outstanding. They helped us improve our SEO rankings through a detailed SEO audit of our website and an action plan with clear instructions.</p>
                  </div>
                </div>

                {/* Review Slide 5 */}
                <div className="rt1_ro">
                  <div className="d-flex flex-column">
                    <span className="nmcrs_l">Andew D.</span>
                    <img className="reviw_stars" src="images/stars.webp" alt="rating" />
                    <span className="dtrv_1">Jan 23,2023 - Apr 3 2023</span>
                  </div>
                  <div className="revews_cnt">
                    <p>I work with Eric C regularly to assist me with setting up conversion tracking across GA4, and Google Tag Manager. Eric is very reliable and prompt with all the requests and completes these to a really high standard for us. Highly recommend!</p>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <section className="lst_ftrlnd">
        <div className="container-fluid ftrlnd_fl">
          <div>
          <div className="ftt1_clm"><img src="Images/white_logo.png" alt="logo" /><p>Uniwork Technologies is a trusted digital marketing agency specializing in SEO, social media marketing, PPC advertising, content creation, and more. Contact us for online success.</p><ul class="scl_1"><li><Link to="https://www.facebook.com/uniworkteam" target="_blank"><img src="images/fb.png" alt="Facebook" /></Link></li><li><Link to="https://www.instagram.com/uniworktechnologies/" target="blank"><img src="images/inst.png" alt="Instagram" /></Link></li><li><Link to="https://www.linkedin.com/company/77836743/admin/feed/posts/" target="_blank"><img src="images/lnkdn.png" alt="LinkedIn" /></Link></li></ul></div> 
          </div>
          <div className="cnt_usare">
           <div className="clm_nmcnts"> 
            <p>Contant Us</p>
            <div className="in_lncnc">
              <img src="images/sslc.png" alt="location" />
              <p>D-151, Phase-8, Industrial Area, Sector 71, Sahibzada Ajit Singh Nagar, Punjab 160071 India </p>
            </div>
            <div className="in_lncnc">
              <img src="images/sslc1.png" alt="phone" />
              <p><Link to="tel:+916239452423">          
           <span>Phone:</span> +91 6239452423</Link></p>
            </div>
            <div className="in_lncnc">
              <img src="images/sslc2.png" alt="mail" />
              <p className="">  <Link to="mailto:uniworkitsolution@gmail.com"> <span>Email:</span> uniworkitsolution@gmail.com</Link></p> 
            </div>
           </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Marketing;
