import Testimonial from '../components/testimonial';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; 
import Zoom from 'react-reveal/Zoom';
import { Fade } from 'react-reveal';
function Webdesign(){
  const testimonialProps1 = {
    title: "eCommerce UX/UI Design Needed with Figma Experience",
    imageSrc: "images/wb_d1.png",
    testimonialText: "Diksha has been a great partner to work with for the past two years. They're an exceptionally competent UX/UI design team and true Figma power users. Her team is highly responsive and easy to communicate with. I'd highly recommend them for any UX/UI design project.",
  };

  const testimonialProps2 = {
    title: "Design a dashboard in an style similar to rest of app",
    imageSrc: "images/wb_d2.png",
    testimonialText: "Working with Uniwork Technologies has been a delightful experience, and we hope for the opportunity to collaborate with them again. Their expertise in web design, especially their skill in mobile responsiveness, perfectly aligned with our project's goals. They, true professionals, made the process smooth and enjoyable. We look forward to future collaborations.",
  };

  const testimonialProps3 = {
    title: "Minimalist designer required | Brochure design",
    imageSrc: "images/wb_d3.png",
    testimonialText: "I am very happy with Diksha! She is a great designer, flexible, a fantastic communicator, always there for you, and willing to do anything it takes to finish the job.",
  };
  const testimonialProps4 = {
    title: "Word Press Custom Design Development",
    imageSrc: "images/wb_d4.png",
    testimonialText: "I am fortunate to have found Uniwork Technologies. We have worked together extensively to achieve every last necessary result. Theirknowledge and skills are top-notch. As an excellent communicator with a firm grasp of the English language, they are an absolute pleasure to work with. The only reason I am closing this project is to start a new one.",
  };
    return(
        <div className="mainn">
                <Helmet>
        <title>
        Get Stunning Web Designing Services for Your Online Presence</title>
<meta name='description' content='Enhance your online presence with our web design services at Uniwork Technologies. We offer custom templates, engaging products, & landing pages for businesses.' />
      </Helmet>
            <section style={{ paddingTop: '1px' }}>
<div className="container-fluid">
  <Fade up>
     <div className="websec_1 servcemx_wdth2 sec_3  mt-90 text-center pb-40">
                <h1>Web<span style={{ color: '#223464' }}>  Designing</span> </h1>
<p>We provide top-notch Web Designing services to businesses of all sizes. We understand the importance of having a well-designed website that not only looks great but also functions seamlessly. Our team of skilled web designers works closely with clients to create customized websites that meet their unique needs and requirements. We are dedicated to delivering exceptional results that help our clients stand out in today's competitive online marketplace. From simple static websites to complex E-Commerce platforms, we have the expertise to deliver high-quality web designs that help businesses succeed online. </p>
</div>
</Fade>
</div>
<div className="pb-90"> 
  <img src="images/dvlp1.webp" width="100%" alt="Best Web Designing Services" />
</div>
</section>

<section>
<div className="container-fluid">
     <div className=" websec_3 sec_3  mt-3 text-center ">
                <h2>Our<span style={{color: '#223464' }}>  Web Designing Services</span> </h2>
<div className="container mt-5">
  <div className="row">
    <Zoom>
    <div className="col">
      <div className="box_1 box_2" style={{backgroundColor: '#F2F1F1' }}>
      	<img src="images/web31.webp" width="90" alt="Template Designing" />
        <h3>Template Designing</h3>
        <p>Uniwork Technologies creates custom templates optimized for speed and user-friendliness using HTML/CSS. Save time and money while achieving a consistent and professional look across your website.</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
    <div className="col">
      <div className="box_1" style={{backgroundColor: '#F2F1F1' }}>
      	<img src="images/web32.webp" width="90" alt="Product Designing" />
        <h3>Product Designing</h3>
        <p>Uniwork Technologies creates custom product pages that provide a unique and enjoyable shopping experience for your customers. Our expert designers optimize for user-friendliness and fast loading times, making it easier for your visitors to find and purchase the products they want. This increases the likelihood of repeat business and builds brand loyalty.</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
    <div className="col">
      <div className="box_1 box_2" style={{backgroundColor: '#F2F1F1' }}>
      	<img src="images/web33.webp" width="90" alt="Landing Page Designing" />
        <h3>Landing Page Designing</h3>
        <p>Our expert designers create custom landing pages tailored to your business needs, goals, and target audience. We optimize for user engagement, ensuring your visitors have a positive experience and take action.</p>
      </div>
    </div>
    </Zoom>
  </div>
  <div className="ttl_ss_des"><p>Your website, your brand's visuals—our expertise. Contact <Link to="/">Uniwork Technologies</Link> today to see how our <Link to="/web-Development">website Development</Link>, <Link to="/graphic-design">Graphic Designing</Link>, <Link to="/website-maintenance">Web Maintenance,</Link> and <Link to="/digital-marketing-agency">Digital Marketing Services</Link>. Can elevate your online presence.</p></div>
</div>
</div>
</div>
</section>
<section className="bg-light services_callsec mt-90">
      <div className="container">
        <div className="row align-items-center">  
          <div className="col-md-6">
            <div>
              <h2>Schedule a call</h2>
              <h2>now with our</h2>
              <h2>Web Designing</h2>
              <h2>expert!</h2>
            </div>
            <div className='btn_areaservsec'>
              <img src='images/right_arrow.png'/>
              <button className="btn_services shed_call">
      <Link to="https://wa.me/06239452423" target='_blank'>Contact Us</Link>
    </button>
            </div>
          </div>
          <div className="col-md-6">
            {/* <img src="images/smiling-woman-headset-presentation-something.png" alt="Background Image" className="img-fluid" /> */}
          </div>
        </div>
      </div>
    </section>
    <div>
    <section className="mt-90 pb-90 abt_last7 mx-4" aria-label='Ut back'>
<div className="mx_wdth2 sec_3 mt-90 pb-90 text-center">
<p className="svr_12 pt-3">- TESTIMONIAL -</p>
<h2>See What People Say<span style={{ color: '#223464' }}> About Us</span></h2>
</div>
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner abt-tsti1">
          <div className="carousel-item active p-4">
            <Testimonial {...testimonialProps1} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps2} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps3} />
          </div> 
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps4} />
          </div>             
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"><img src="images/lft.webp" alt="Breadcrumb Left"/></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"><img src="images/rgt.webp" alt="Breadcrumb Right"/></span>
      <span className="visually-hidden">Next</span>
    </button>
      </div>
      </section>
      </div>
        </div>
    );
}

export default Webdesign;