import Testimonial from '../components/testimonial';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; 
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
function Payperclick(){
   const testimonialProps1 = {
    title: "My PPC Campaign Needs Help",
    imageSrc: "images/pp_c1.png",
    testimonialText: "Our experience with Uniwork Technologies has been great. They listen well and effectively address the problems at hand. We would love to hire them again for future projects!",
  };

  const testimonialProps2 = {
    title: "PPC Campaign",
    imageSrc: "images/pp_c2.png",
    testimonialText: "Eric Case provided excellent service for our company. As our business at the law firm slowed due to the pandemic, our PPC campaign, managed by Eric, helped us keep busy with new cases. Thank you, Eric, for your work.",
  };

  const testimonialProps3 = {
    title: "Audit our Google Ads campaign/platform setup for healthcare lead generation",
    imageSrc: "images/pp_c3.png",
    testimonialText: "An expert and consummate professional, Uniwork Technologies leveraged their vast Google knowledge to help us audit our campaigns and uncover areas for improvement. We're thankful for their help!",
  };
  const testimonialProps4 = {
    title: "Optimizing Google ads for Cleaning company",
    imageSrc: "images/pp_c4.png",
    testimonialText: "Eric, a professional in Google Ads, is always up to date on new features and displays creativity in implementing our brand and company with these new features. His work is timely and courteous, making him a valuable asset to our company.",
  };
    return(
        <div className="mainn">
 <Helmet>
        <title>
        Boost Your ROI with Our Pay-Per-Click Marketing Services</title>
<meta name='description' content='Want to optimize your PPC marketing campaigns? Uniwork Technologies is a leading Pay-per-click advertising agency offering PPC services & plans for paid ads.' />
      </Helmet>
<section style={{ paddingTop : '1px' }}>

<div className="container-fluid">
  <Fade up>
     <div className="websec_1 servcemx_wdth2 sec_3  mt-90 text-center pb-40">
                <h1>Pay-Per-Click<span style={{ color: '#223464' }}> Marketing</span> </h1>
<p>As a top-leading PPC agency, empowering businesses to effectively reach their target audience and maximize their online presence is our mission. With an expert team of Digital Marketers, strategic PPC advertising campaigns are tailored to specific goals and objectives.  Whether you're looking to boost website traffic, increase conversions, or enhance brand visibility, our customized PPC services are designed to deliver results. We leverage Cutting-Edge PPC Marketing Strategies, Keyword Research, Ad Optimization, and Data-Driven Analytics to ensure that your advertising budget is invested wisely, providing a strong Return On Investment (ROI). At Uniwork Technologies, we are committed to staying at the forefront of the dynamic digital advertising landscape, ensuring that your business maintains its competitiveness and visibility. Team up with us and let the best Pay-Per-Click marketing methods drive your business forward. </p>
</div>
</Fade>
</div>
</section>

<section>
<div class="pb-90"> 
  <img src="images/paybnr.webp" width="100%" alt="Our Pay-Per-Click Marketing Services"/>
</div>
</section>

<section>
<div className="container-fluid">
     <div className=" websec_3 sec_3 mt-3 text-center ">
                <h2>Our<span style={{ color: '#223464' }}> Pay-Per-Click Marketing Services</span> </h2>

<div className="container mt-5 ppc_ct">
  <div className="row mb-5">
    <Zoom>
    <div className="col">
      <div className="box_1 analyticsbox_2" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Customized-Strategy-Development.png" width="90" alt="Customized Strategy Development - best Pay-Per-Click Marketing Services"/>
       <h3>Customized Strategy Development</h3>
        <p>We understand that each business is unique. Our team collaborates with you to develop a tailored PPC strategy that aligns with your business goals and objectives. We take into account your industry, target audience, and budget to craft a winning plan.
</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
    <div className="col">
      <div className="box_1 " style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Keyword-Research-and-Selection.png" width="90" alt="Keyword Research and Selection -  PPC agency"/>
        <h3>Keyword Research and Selection</h3>
        <p>Keywords are the foundation of any successful PPC campaign. We conduct thorough keyword research to identify the most relevant and high-converting keywords for your business. This ensures that your ads are displayed to users actively searching for your products or services.
</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
    <div className="col">
      <div className="box_1 paybox_3 " style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Ad-Creation-and-Optimization.png" width="90" alt="Ad Creation and Optimization - PPC strategy"/>
        <h3>Ad Creation and Optimization</h3>
        <p>Our team designs compelling and eye-catching PPC ad campaigns that resonate with your audience. We continuously monitor and optimise ad copy, visuals, and landing pages to improve click-through rates and conversions.</p>
      </div>
    </div>
    </Zoom>
  </div>
  <div className="row">
    <Zoom>
    <div className="col">
      <div className="box_1 analyticsbox_2" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Bid-Management.png" width="90" alt="Bid Management - PPC services"/>
       <h3>Bid Management</h3>
        <p>We manage your bids effectively to ensure that your ads are displayed at the right time and to the right audience. Our bid optimization strategies help you maximize your ROI while staying within your budget.
</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
    <div className="col">
      <div className="box_1 " style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/Performance-Tracking-and-Reporting.png" width="90" alt="Performance Tracking and Reporting - PPC advertising campaigns"/>
        <h3>Performance Tracking and Reporting</h3>
        <p>Transparency is vital. We provide detailed performance reports that showcase key metrics, such as Click-Through Rates, Conversion Rates, and Cost-Per-Click. This Data-Driven approach allows us to refine our strategies and drive continuous improvement.
</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
    <div className="col">
      <div className="box_1 paybox_3 " style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/AB-Testing.png" width="90" alt="A/B Testing -  PPC marketing"/>
        <h3>A/B Testing</h3>
        <p>By conducting A/B tests, we assess various ad components and strategies, enabling us to identify the most successful approaches for your campaigns. This iterative approach ensures that we are always fine-tuning your PPC efforts for optimal results.
</p>
      </div>
    </div>
    </Zoom>
  </div>
  <div className="ttl_ss_des"><p>Connect with <Link to="/">Uniwork Technologies</Link> for PPC success. Contact us today to get started and explore our full range of <Link to="/digital-marketing-agency">Digital Marketing Services</Link> for a comprehensive solution to your online needs.</p></div>
</div>


            </div>
			</div>
</section>
<section className="bg-light services_callsec mt-90">
      <div className="container">
        <div className="row align-items-center">  
          <div className="col-md-6">
            <div>
              <h2>Schedule a call</h2>
              <h2>now with our</h2>
              <h2>PPC marketing</h2>
              <h2>expert!</h2>
            </div>
            <div className='btn_areaservsec'>
              <img src='images/right_arrow.png'/>
              <button className="btn_services shed_call">
      <Link to="https://wa.me/06239452423" target='_blank'>Contact Us</Link>
    </button>
            </div>
          </div>
          <div className="col-md-6">
            {/* <img src="images/smiling-woman-headset-presentation-something.png" alt="Background Image" className="img-fluid" /> */}
          </div>
        </div>
      </div>
    </section>
    <div>
    <section className="mt-90 pb-90 abt_last7 mx-4" aria-label='Ut back'>
<div className="mx_wdth2 sec_3 mt-90 pb-90 text-center">
<p className="svr_12 pt-3">- TESTIMONIAL -</p>
<h2>See What People Say<span style={{ color: '#223464' }}> About Us</span></h2>
</div>
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner abt-tsti1">
          <div className="carousel-item active p-4">
            <Testimonial {...testimonialProps1} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps2} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps3} />
          </div>  
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps4} />
          </div>        
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"><img src="images/lft.webp" alt="Breadcrumb Left"/></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"><img src="images/rgt.webp" alt="Breadcrumb Right"/></span>
      <span className="visually-hidden">Next</span>
    </button>
      </div>
      </section>
      </div>
        </div>
    );
}

export default Payperclick;