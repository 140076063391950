import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function Marketing() {
  const slidesData = [
    "Increase Visibility",
    "Boost Sales",
    "Capture Attention",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slidesData.length);
    }, 2000); // Adjust the interval time (ms) to control the slideshow speed

    return () => clearInterval(interval);
  }, [slidesData.length]);
  return (
    <>
      <section className="bnrdg_2">
        <div className="container-fluid">
          <div className="row pb-5 align-items-center">
            <div className="col-sm-6 mt-4 pb-2">
              <h1 className="hd_bnr1">Accelerate Growth with our Data-Driven<br />Digital Marketing Services</h1>
              <div className="slide-container">
              {slidesData.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentIndex ? 'active' : ''}`}
            style={{ color: '#223464' }}
          >
            {slide}
          </div>
        ))}
              </div>
              <h2 className="uniwk_2">With Uniwork Technologies</h2>
              <p className="pwr_1">Powering the Success of Your Business</p>
              <p className="dtful_1">Data fuels business growth in the digital age. <span style={{ color: '#223464', fontWeight: 600 }}>Uniwork Technologies</span> specializes in data-driven <span style={{ textTransform: 'uppercase' }}>digital marketing services</span>, empowering businesses to achieve growth objectives.</p>
            </div>

            <div className="col-sm-6 fmbx_1">
              <div className="container">
              <form className="digtlfom_1">
      <h3>Let's Work Together</h3>
      <p className="fil_1">*Please fill out all fields.</p>
      <div className="upr_1">
        <div className="onbr">
          <label htmlFor="fname">First Name*</label>
          <input type="text" id="fname" name="firstname" placeholder="" />
        </div>
        <div className="onbr">
          <label htmlFor="lname">Last Name*</label>
          <input type="text" id="lname" name="lastname" placeholder="" />
        </div>
      </div>

      <div className="upr_1 upr_2">
        <div className="onbr">
          <label htmlFor="email">Email*</label>
          <input type="email" id="email" name="email" />
        </div>
        <div className="onbr">
          <label htmlFor="phone">Phone Number*</label>
          <input type="tel" id="phone" name="phone" />
        </div>
      </div>

      <label htmlFor="Services">Services*</label>
      <div className="rdbtn_1">
        <div className="rdflx_1">
          <div className="rdist_1">
            <input type="radio" id="WebsiteDataAnalytics" name="service" required />
            <label htmlFor="WebsiteDataAnalytics">Website & Data Analytics</label>
          </div>
          <div className="rdist_1">
            <input type="radio" id="SocialMediaMarketing" name="service" required />
            <label htmlFor="SocialMediaMarketing">Social Media Marketing</label>
          </div>
        </div>

        <div className="rdflx_1">
          <div className="rdist_1">
            <input type="radio" id="PPCMarketing" name="service" required />
            <label htmlFor="PPCMarketing">PPC Marketing</label>
          </div>
          <div className="rdist_1">
            <input type="radio" id="SearchEngineOptimization" name="service" required />
            <label htmlFor="SearchEngineOptimization">Search Engine Optimization</label>
          </div>
        </div>

        <div className="rdflx_1">
          <div className="rdist_1">
            <input type="radio" id="SearchEngineMarketing" name="service" required />
            <label htmlFor="SearchEngineMarketing">Search Engine Marketing</label>
          </div>
          <div className="rdist_1">
            <input type="radio" id="ConversionRateOptimization" name="service" required />
            <label htmlFor="ConversionRateOptimization">Conversion Rate Optimization</label>
          </div>
        </div>
      </div>

      <label htmlFor="Message">Message</label>
      <textarea id="Message" name="subject" placeholder="" style={{ height: '200px' }} />

      <div className="btnfomdgt_1">
        <input type="submit" value="Let Us Hear From You" />
      </div>
    </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row mt-90 align-items-center">
            <div className="col-sm-6 dscvr_1">
              <h2>Discover How Our Online Marketing Services Can Skyrocket Your Business</h2>
              <p>Our team of experienced professionals understands the power of data and its ability to uncover valuable insights about your target audience, market trends, and campaign performance. By leveraging the latest analytics tools and techniques, we gather, analyze, and interpret data to make informed decisions and drive your digital marketing strategies.</p>
            </div>
            <div className="col-sm-6 dscvr_21">
              <img src="images/dg2.webp" width="90%" alt="dg" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="websec_3 sec_3 mt-90 text-center ">
            <h2 className="srv_34">Check Out Our <span className="bordr_23">Services!</span></h2>
            <div className="mt-5 text-start">
              <div className="row">
                <div className="col">
                  <div className="anlytc_12">
                    <img src="images/dl1.webp" alt="dl1" />
                    <h3>Website & Data Analytics Services</h3>
                    <p>Elevate your web presence with our website & data analytics services. Gain insights from website traffic, user behavior, and key performance indicators with our comprehensive data analytics services. Make data-driven decisions & enhance your digital presence with our services.</p>
                    <ul className="blt_1">
                      <p>Key points:</p>
                      <li>Data Collection and Tracking.</li>
                      <li>User Behavior Analysis.</li>
                      <li>Performance Metrics and Optimization.</li>
                      <li>Visualization and Reporting.</li>
                    </ul>
                  </div>
                </div>
                <div className="col">
                  <div className="anlytc_12">
                    <img src="images/dl2.webp" alt="dl2" />
                    <h3>Pay-Per-Click (PPC) Services</h3>
                    <p>Unlock the potential of your business with our PPC Management services. Our dedicated team ensures exceptional results through strategic planning, meticulous campaign management, and continuous optimization. With our targeted advertising campaigns, we deliver the desired results.</p>
                    <ul className="blt_1">
                      <p>Key points:</p>
                      <li>Targeted PPC campaigns for conversions and sales.</li>
                      <li>Thorough keyword research for customer reach.</li>
                      <li>Compelling ad creation and optimization.</li>
                      <li>Effective budget management.</li>
                    </ul>
                  </div>
                </div>
                <div className="col">
                  <div className="anlytc_12">
                    <img src="images/dl3.webp" alt="dl3" />
                    <h3>Search Engine Optimization (SEO)</h3>
                    <p>Boost your website's visibility and drive organic traffic with our comprehensive SEO services. Our expertise in SEO strategies aims to improve rankings in search engine results pages (SERPs) for relevant keywords. We optimize website elements, backlinks, and deliver measurable results aligned with your business goals.</p>
                    <ul className="blt_1">
                      <p>Key points:</p>
                      <li>Comprehensive Website Analysis and Optimization.</li>
                      <li>On-Page Optimization for Improved Visibility.</li>
                      <li>Off-Page Optimization Strategies.</li>
                      <li>Technical SEO Enhancements.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 pb-5 text-start">
              <div className="row">
                <div className="col">
                  <div className="anlytc_12">
                    <img src="images/dl4.webp" alt="dl4" />
                    <h3>Social Media Marketing (SMM)</h3>
                    <p>Engage your target audience and generate tangible results with our Social Media Marketing services. Our experts leverage social media platforms to create strategies, optimize content, and performance. Unlock social media's potential for your brand's success.</p>
                    <ul className="blt_1">
                      <p>Key points:</p>
                      <li>Tailored strategies for impact.</li>
                      <li>Content creation, engagement, collaborations.</li>
                      <li>Paid advertising for visibility.</li>
                      <li>Performance tracking for improvement.</li>
                    </ul>
                  </div>
                </div>
                <div className="col">
                  <div className="anlytc_12">
                    <img src="images/dl5.webp" alt="dl5" />
                    <h3>Search Engine Marketing (SEM)</h3>
                    <p>Online visibility and targeted traffic generation are our specialties. In the competitive digital landscape, a strong search engine presence is essential. Our Search Engine Marketing (SEM) services optimize online visibility and attract relevant traffic.</p>
                    <ul className="blt_1">
                      <p>Key points:</p>
                      <li>Enhance online visibility.</li>
                      <li>Keyword research, ad creation, & targeting.</li>
                      <li>Ads are placed based on auctions and relevance.</li>
                      <li>SEM focuses on boosting ROI.</li>
                    </ul>
                  </div>
                </div>
                <div className="col">
                  <div className="anlytc_12">
                    <img src="images/dl6.webp" alt="dl6" />
                    <h3>Conversion Rate Optimization (CRO)</h3>
                    <p>Get the most out of your online presence with Conversion Rate Optimization (CRO). Our expert team specializes in optimizing websites and digital platforms to improve conversion rates, boost customer engagement, and drive revenue growth.</p>
                    <ul className="blt_1">
                      <p>Key points:</p>
                      <li>Improves website performance.</li>
                      <li>CRO uses data analysis, A/B testing & Improvements.</li>
                      <li>It optimizes user experience & increases conversions.</li>
                      <li>The goal of CRO is to maximize ROI.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hpy_clint_sec">
        <div className="container-fluid mt-90 pb-5">
          <div className="row">
            <div className="titls_clnt pb-4 text-center">
              <h2 className="">Our Happy <span className="bordr_23">Clients</span></h2>
              <p>We believe in going the extra mile for our clients</p>
            </div>
            <div className="col-lg-3">
              <img className="hp_logo" src="images/Group-9845.webp" alt="logo" />
            </div>
            <div className="col-lg-9 crl_it">
              {/* Owl Carousel for the reviewSlider */}
              <OwlCarousel
                className="owl-theme"
                loop={true}
                nav={true}
                dots={false}
                items={3}
                responsive={{
                  0: {
                    items: 1,
                  },
                  768: {
                    items: 3,
                  },
                }}
              >
                {/* Review Slide 1 */}
                <div className="rt1_ro">
                  <div className="d-flex flex-column">
                    <span className="nmcrs_l">Andrew D.</span>
                    <img className="reviw_stars" src="images/stars.webp" alt="rating" />
                    <span className="dtrv_1">Dec 8, 2022 - Feb 22, 2023</span>
                  </div>
                  <div className="revews_cnt">
                    <p>Uniwork Technologies is a great company to work with – they are very reliable, knowledgeable, and proficient. They helped us with everything from setting up a new Google Data Studio to verifying that our attribution tracking across multiple clients was correct. I absolutely recommend Uniwork Technologies!</p>
                  </div>
                </div>

                {/* Review Slide 2 */}
                <div className="rt1_ro">
                  <div className="d-flex flex-column">
                    <span className="nmcrs_l">Shari A.</span>
                    <img className="reviw_stars" src="images/stars.webp" alt="rating" />
                    <span className="dtrv_1">Jul 11, 2022 - Sep 2, 2022</span>
                  </div>
                  <div className="revews_cnt">
                    <p>It was a pleasure to work with Uniwork Technologies. They communicated frequently and replied to messages promptly. They completed the project within the estimated timeframe. I will definitely hire Uniwork Technologies again for future projects.</p>
                  </div>
                </div>

                {/* Review Slide 3 */}
                <div className="rt1_ro">
                  <div className="d-flex flex-column">
                    <span className="nmcrs_l">Amanda R.</span>
                    <img className="reviw_stars" src="images/stars.webp" alt="rating" />
                    <span className="dtrv_1">Jan 23, 2023 - Apr 3, 2023</span>
                  </div>
                  <div className="revews_cnt">
                    <p>Uniwork Technologies was very structured and professional, providing us with tremendous assistance during and after the project. Their expertise and support were invaluable, and we greatly appreciated their dedication.</p>
                  </div>
                </div>

                {/* Review Slide 4 */}
                <div className="rt1_ro">
                  <div className="d-flex flex-column">
                    <span className="nmcrs_l">Valters L.</span>
                    <img className="reviw_stars" src="images/stars.webp" alt="rating" />
                    <span className="dtrv_1">May 27, 2022 - Apr 27, 2023</span>
                  </div>
                  <div className="revews_cnt">
                    <p>Working with Uniwork Technologies was a great experience. Their advertising campaigns were expertly crafted. It showcased their outstanding skills and unwavering professionalism, and I would not hesitate to work with them again. I wholeheartedly recommend Uniwork Technologies to anyone looking for exceptional advertising campaigns. Thank you for the fantastic work!</p>
                  </div>
                </div>

                {/* Review Slide 5 */}
                <div className="rt1_ro">
                  <div className="d-flex flex-column">
                    <span className="nmcrs_l">Louis H.</span>
                    <img className="reviw_stars" src="images/stars.webp" alt="rating" />
                    <span className="dtrv_1">Apr 13, 2023 - Jun 28, 2023</span>
                  </div>
                  <div className="revews_cnt">
                    <p>The goal of this project was to find a highly conscientious and detail-oriented company to handle the implementation of technical SEO improvements on the website. Thankfully, we found the perfect company in Uniwork Technologies. Uniwork Technologies is not only highly detail-oriented but also executes fixes quickly and presents changes in an organized, easy-to-track format. I highly recommend Uniwork Technologies and will certainly be partnering with them again in the future.</p>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Marketing;
