import Testimonial from '../components/testimonial';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; 
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import React, { useEffect } from 'react';
function Graphicdesign(){
  const testimonialProps1 = {
    title: "Need some quick editing to a brochure",
    imageSrc: "images/grp_f1.png",
    testimonialText: "Working with Uniwork Technologies has been excellent! They finished our project extremely quickly and exceeded our expectations.",
  };

  const testimonialProps2 = {
    title: "Catalog/Brochure Design",
    imageSrc: "images/grp_f2.png",
    testimonialText: "Uniwork Technologies was phenomenal for our company! They exceeded our expectations with the brochure. They were able to deliver everything we wanted and make suggestions that we used in the final product. They were very professional and really easy to work with. We would definitely recommend Uniwork Technologies.",
  };

  const testimonialProps3 = {
    title: "Graphic design for back of bookmark",
    imageSrc: "images/grp_f3.png",
    testimonialText: "Diksha provided a fantastic experience for our company. I got the outcome I wanted on the same day—truly amazing. Their prompt service exceeded our expectations, and we'll definitely turn to her company for future projects.",
  };
  const testimonialProps4 = {
    title: "Seeking Typography Designs for Print On Demand Business",
    imageSrc: "images/grp_f4.png",
    testimonialText: "Uniwork Technologies is one of the most talented design teams we've worked with. Besides their professionalism and technical expertise, they have that natural artistic ability you can't teach - something probably one in a million people are born with.",
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Ensure the page starts at the top when the component mounts.
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smoothly scroll to the top
  };
    return(
        <div className="mainn">
              <Helmet>
        <title>
        Innovative Graphic Design Solutions | Uniwork Technologies</title>
<meta name='description' content='Transform your brands visual identity with Uniwork Technologies graphic design services. From eye-catching logos to stunning visuals. Get started today!' />
      </Helmet>
            <section style={{ paddingTop : '1px' }}>
<div className="container-fluid">
  <Fade up>
     <div className="websec_1 servcemx_wdth2 sec_3  mt-90 text-center pb-40">
                <h1>Graphic<span style={{ color: '#223464' }}> Designing</span> </h1>
<p>Looking for professional <b>Graphic Designing </b>services? Our company has got you covered. Our team of skilled designers is dedicated to delivering customized solutions that cater to the unique needs of your business. We use <b>cutting-edge tools</b> and techniques to create <b>visually stunning designs </b> that capture the essence of your brand. From <b>Logo Design</b> to <Link to="/web-development">Website Development</Link> and marketing collateral, we provide end-to-end solutions that help you stand out from the competition. Our focus on creativity, attention to detail, and commitment to excellence ensures that we deliver high-quality designs that exceed your expectations.</p>
</div>
</Fade>
</div>
</section>
            
<section>
<div className="pb-90"> 
  <img src="images/grphbnr.webp" width="100%" alt="Top Graphic Designing"/>
</div>
</section>

<section>
	<div className="container-fluid">
     <div className=" websec_3 sec_3  mt-3 text-center ">
                <h2>Our<span style={{ color: '#223464' }}> Graphic Designing Services</span> </h2>

<div className="container mt-5">
  <div className="row">
    <Zoom>
    <div className="col">
      <div className="box_1 seobox_3" style={{ backgroundColor: '#F2F1F1' }} >
      	<img src="images/grph1.webp" width="90" alt="Prototypes & Branding"/>
        <h3>Prototypes & Branding</h3>
        <p>Experience the power of creative design solutions as we bring your ideas to life. Our expertise extends from crafting wireframes to developing full-fledged prototypes, enabling you to visualize and realize your unique vision. Let us guide you on a journey of transformation, where imagination meets tangible outcomes.</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
      <div className="col">
      <div className="box_1" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/grph2.webp" width="90" alt="Unique Logo & Branding"/>
        <h3>Unique Logo & Branding</h3>
        <p>Make a lasting impression that sets you apart with a memorable logo and comprehensive branding package. Our design experts specialize in creating distinctive visual identities tailored to your brand. Trust us to help you shine in a crowded market, leaving a remarkable mark on your audience.</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
    <div className="col">
      <div className="box_1 grphicbox_3" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/grph3.webp" width="90" alt="Website Design And Development"/>
        <h3>Website Design</h3>
        <p>Our team of talented designers will meticulously craft a bespoke website that embodies your brand essence, delivering a seamless user experience and a captivating visual journey. Witness the transformative power as we empower your digital presence, leaving an indelible impression on visitors and fostering meaningful engagement that propels your success.</p>
      </div>
    </div>
    </Zoom>
  </div>
  <div className="ttl_ss_des"><p>Ready to elevate your brand with stunning <Link to="/graphic-design" onClick={scrollToTop}>Graphic Design Services</Link>? Uniwork Technologies is ready to turn your vision into reality. Contact us today to discuss your unique design needs, as well as our <Link to="/web-design">Web Designing</Link>, website development, and <Link to="/website-maintenance">Web Maintenance Services</Link>. Discover how <Link to="/">Uniwork Technologies</Link> can assist your business succeed.</p></div>
</div>


            </div>
			</div>
</section>
<section className="bg-light services_callsec mt-90">
      <div className="container">
        <div className="row align-items-center">  
          <div className="col-md-6">
            <div>
              <h2>Schedule a call</h2>
              <h2>now with our</h2>
              <h2>Graphic Designing</h2>
              <h2>expert!</h2>
            </div>
            <div className='btn_areaservsec'>
              <img src='images/right_arrow.png'/>
              <button className="btn_services shed_call">
      <Link to="https://wa.me/06239452423" target='_blank'>Contact Us</Link>
    </button>
            </div>
          </div>
          <div className="col-md-6">
            {/* <img src="images/smiling-woman-headset-presentation-something.png" alt="Background Image" className="img-fluid" /> */}
          </div>
        </div>
      </div>
    </section>
    <div>
    <section className="mt-90 pb-90 abt_last7 mx-4" aria-label='Ut back'>
<div className="mx_wdth2 sec_3 mt-90 pb-90 text-center">
<p className="svr_12 pt-3">- TESTIMONIAL -</p>
<h2>See What People Say<span style={{ color: '#223464' }}> About Us</span></h2>
</div>
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner abt-tsti1">
          <div className="carousel-item active p-4">
            <Testimonial {...testimonialProps1} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps2} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps3} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps4} />
          </div>           
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"><img src="images/lft.webp" alt="Breadcrumb Left"/></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"><img src="images/rgt.webp" alt="Breadcrumb Right"/></span>
      <span className="visually-hidden">Next</span>
    </button>
      </div>
      </section>
      </div>
            </div>
    );
}

export default Graphicdesign;