import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'; 
import Zoom from 'react-reveal/Zoom';
import { Fade } from 'react-reveal';
function Services() {
  const [expandedCards, setExpandedCards] = useState([]);

  const toggleText = (index) => {
    if (expandedCards.includes(index)) {
      setExpandedCards(expandedCards.filter((cardIndex) => cardIndex !== index));
    } else {
      setExpandedCards([index]);
    }
  };

  return (
        <div className="mainn">
   <Helmet>
        <title>
        Digital Marketing Solutions & Custom Web Design Services</title>
<meta name='description' content='Experience online excellence with Uniwork Technologies. Our experts offer web design, development, Digital Marketing, & SEO services tailored to your success.' />
      </Helmet>
<section className="row ab_1 servi_sec sec_1" style={{ backgroundImage: "url('images/srvebnr.webp')" }} aria-label='Web Designing And Development Services'>
  <Fade up>
        <div className="websec_1  mx_wdth2 text-center text-white">
          <h1>Services Tailored to<i className="brdr_stitle" aria-label='Border'>  Your Goals</i></h1>
          <div className="btnsrvce_1 pt-2">
            <button className="hdrbtn_1 servc hdrbtn_2"><Link to="/portfolio">View Portfolio</Link></button>
            <button className="hdrbtn_1 hdrbtn_2"><Link to="/contact">Contact Us</Link></button>
          </div>
        </div>
        </Fade>
      </section>

      <section>
        <div className="container-fluid">
          <div className="mx_wdth2 sec_3 sec_212  mt-90 text-center">
            <h2>Our<span style={{ color: '#223464' }}> Services</span></h2>
            <p>Welcome to <b>Uniwork Technologies</b>, your partner in digital transformation. Our IT company provides a wide range of services to help businesses succeed in the digital age. We are passionate about delivering innovative solutions that help our clients achieve their goals.</p>
          </div>
          <div id="cards_landscape_wrap-2">
            <div className="container">              
              <div className="row">
              <Zoom>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                  <div className="card-flyer">
                    <div className="text-box">
                      <div className="image-box">
                        <img src="images/imgbox1.webp" alt="Web Designing Services" />
                      </div>
                      <div className="text-container">
                        <img className="smimgsrv_1" src="images/ssm1.webp" alt="Web Designing" />
                        <Link to="/web-design"><h3>Web Designing</h3></Link>
                        <p>
                          We provide top-notch Web Designing services to businesses of all sizes.
                          We understand the importance of having a well-designed Website that not only looks great {expandedCards.includes(0) ? ( <> 
                              but also functions seamlessly. Our team of skilled web designers works closely with clients to create customized Websites that meet their unique needs and requirements. We are dedicated to delivering exceptional results that help our clients stand out in today's competitive online marketplace. From simple static Websites to complex e-commerce platforms, we have the expertise to deliver high-quality web designs that help businesses succeed online.
                            </>
                          ) : (
                            <>
                              <span id="dots"></span>
                              <span className="more">...</span>
                            </>
                          )}
                        </p>
                        <button onClick={() => toggleText(0)} className="toggle-btn">
                          {expandedCards.includes(0) ? 'Read less' : '....Read more'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </Zoom>
                <Zoom>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                  <div className="card-flyer">
                    <div className="text-box">
                      <div className="image-box">
                        <img src="images/imgbox2.webp" alt="Web Development Services" />
                      </div>
                      <div className="text-container">
                        <img className="smimgsrv_1" src="images/ssm2.webp" alt="Professional Web Development Services" />
                        <Link to="/web-development"><h3>Web Development</h3></Link>
                        <p>
                          Our company offers professional Web Development services to help businesses establish a strong online presence. Our experienced team of web developers   {expandedCards.includes(1) ? (      <>
                              specializes in creating custom Websites that are tailored to meet the unique needs of our clients. We use the latest Web Development technologies and best practices to ensure that our clients' Websites are not only visually appealing but also highly functional, responsive, and user-friendly. From e-commerce platforms to content management systems, our Web Development services cover a wide range of solutions to help our clients achieve their business objectives. Contact Us today to learn how we can help you enhance your online presence and drive more traffic to your Website.
                              </>
                          ) : (
                            <>
                              <span id="dots"></span>
                              <span className="more">...</span>
                            </>
                          )}
                        </p>
                        <button onClick={() => toggleText(1)} className="toggle-btn">
                          {expandedCards.includes(1) ? 'Read less' : '....Read more'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </Zoom>
                <Zoom>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                        <div className="card-flyer">
                            <div className="text-box">
                                <div className="image-box">
                                     <img src="images/digitalmarkiting.webp" alt="Digital Marketing Services" />
                                </div>
                                <div className="text-container">  
                                   <img className="smimgsrv_1" src="images/ssm3.webp" alt="SEO Services"/>                                  
                                   <Link to="/digital-marketing-agency"><h3>Digital Marketing</h3></Link>
                                    <p> We offer comprehensive Digital Marketing services to help businesses succeed online. Our services include Search Engine Optimization (SEO), Website and Data Analytics,                                 
                                        Pay-Per-Click{expandedCards.includes(8) ? (                                <> (PPC) advertising,  Search Engine Marketing (SEM), Conversion Rate Optimization (CRO), Social Media Marketing (SMM), Email Marketing, and more. We tailor our strategies to each client's unique goals and utilize data-driven approaches for optimal results. Whether you're a small business or a large enterprise, we can enhance your online presence, increase brand visibility, and drive meaningful outcomes. Partner with us to achieve your Digital Marketing objectives.
                                    </>
                          ) : (
                            <>
                              <span id="dots"></span>
                              <span className="more">...</span>
                            </>
                          )}
                            </p>
                            <button onClick={() => toggleText(8)} className="toggle-btn">
                          {expandedCards.includes(8) ? 'Read less' : '....Read more'}
                        </button>
                                </div>
                            </div>
                        </div>
                   </div>  
                   </Zoom>
                   <Zoom>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                        <div className="card-flyer">
                            <div className="text-box">
                                <div className="image-box">
                                    <img src="images/imgbox4.webp" alt="Graphic Designing Services" />
                                </div>
                                <div className="text-container">
                                       <img className="smimgsrv_1" src="images/ssm4.webp" alt="Professional Graphic Designing Services"/>
                                       <Link to="/graphic-design"> <h3>Graphic Designing</h3></Link>
                                    <p>Looking for professional Graphic Designing services? Our company has got you covered. Our team of skilled designers is dedicated to delivering customized solutions that cater to {expandedCards.includes(3) ? (                                <>
                                       the unique  needs of your business. We use cutting-edge tools and techniques to create visually stunning designs that capture the essence of your brand. From logo design to Website development and marketing collateral, we provide end-to-end solutions that help you stand out from the competition. Our focus on creativity, attention to detail, and commitment to excellence ensures that we deliver high-quality designs that exceed your expectations. Get in touch with us today to learn more about our services and how we can help your business succeed.
                                        </>
                          ) : (
                            <>
                              <span id="dots"></span>
                              <span className="more">...</span>
                            </>
                          )}
                            </p>
                            <button onClick={() => toggleText(3)} className="toggle-btn">
                          {expandedCards.includes(3) ? 'Read less' : '....Read more'}
                        </button>
                                </div>
                            </div>
                        </div>
                </div>
                </Zoom>
                <Zoom>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                        <div className="card-flyer">
                            <div className="text-box">
                                <div className="image-box">
                                     <img src="images/imgbox5.webp" alt="Website Maintenance" />
                                </div>
                                <div className="text-container"> 
                                   <img className="smimgsrv_1" src="images/ssm5.webp" alt="Website Maintenance Services"/>                                   
                                   <Link to="/Website-maintenance"><h3>Website Maintenance</h3></Link>
                                    <p>We ensure that your Website is current, operating faultlessly, functioning at its peak, free of technical problems, enhancing user experience, and maintaining a high ranking {expandedCards.includes(4) ? (                              <>
                                    in Search Engine results pages. We optimize your Website's functionality to enhance its responsiveness and quickness. We routinely scan the Website to find and fix security flaws before they can do any harm.
                                    </>
                          ) : (
                            <>
                              <span id="dots"></span>
                              <span className="more">...</span>
                            </>
                          )}
                            </p>
                            <button onClick={() => toggleText(4)} className="toggle-btn">
                          {expandedCards.includes(4) ? 'Read less' : '....Read more'}
                        </button>
                                </div>
                            </div>
                        </div>
                </div>
                </Zoom>
                <Zoom>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                        <div className="card-flyer">
                            <div className="text-box">
                                <div className="image-box">
                                    <img src="images/imgbox7.webp" alt="Web Analytics" />
                                </div>
                                <div className="text-container">
                                       <img className="smimgsrv_1" src="images/ssm7.webp" alt="Website Traffic Analysis"/>
                                    <Link to="/Website-analytics"><h3>Web Analytics</h3></Link>
                                    <p>Our company provides Web Analytics services to help businesses Measure, Analyze, and Optimize their Website performance. Our services include Website Traffic Analysis, {expandedCards.includes(5) ? (                                <>
                                    conversion tracking, user behavior analysis, A/B testing, and custom reports. We use the latest technology and techniques to provide accurate, actionable data that can help you improve your online presence and drive business growth. Contact Us today to learn more!
                                    </>
                          ) : (
                            <>
                              <span id="dots"></span>
                              <span className="more">...</span>
                            </>
                          )}
                            </p>
                            <button onClick={() => toggleText(5)} className="toggle-btn">
                          {expandedCards.includes(5) ? 'Read less' : '....Read more'}
                        </button>
                                </div>
                            </div>
                        </div>
                      </div>
                </Zoom>
                <Zoom>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                        <div className="card-flyer">
                            <div className="text-box">
                                <div className="image-box">
                                     <img src="images/imgbox3.webp" alt="Search Engine Optimization" />
                                                                    </div>
                                <div className="text-container">
                                       <img className="smimgsrv_1" src="images/ssm3.webp" alt="Comprehensive Digital Marketing Services"/>
                                   <Link to="/seo"><h3>Search Engine Optimization</h3></Link>
                                  <p>We also specialize in providing high-quality Search Engine Optimization services to businesses of all sizes. We understand the importance of having a strong online presence {expandedCards.includes(2) ? (   <>
                            and strive to help our clients achieve top rankings on Search Engines such as Google, Bing, and Yahoo. Our team of experts stays up-to-date with the latest SEO techniques and strategies to ensure that our clients' Websites are fully optimized for Search Engines. We take a personalized approach to every project and work closely with our clients to develop a customized SEO strategy that meets their unique needs and goals. With our SEO services, our clients can expect to see increased Website Traffic, improved Search Engine Rankings, and ultimately, more leads and sales for their business.
                            </>
                          ) : (
                            <>
                              <span id="dots"></span>
                              <span className="more">...</span>
                            </>
                          )}
                            </p>
                            <button onClick={() => toggleText(2)} className="toggle-btn">
                          {expandedCards.includes(2) ? 'Read less' : '....Read more'}
                        </button>
                                </div>
                            </div>
                        </div>
                </div>
                </Zoom>
                <Zoom>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                        <div className="card-flyer">
                            <div className="text-box">
                                <div className="image-box">
                                     <img src="images/imgbox8.webp" alt="PPC marketing services" />
                                </div>
                                <div className="text-container">  
                                   <img className="smimgsrv_1" src="images/ssm8.webp" alt="PPC Marketing"/>                                  
                                    <Link to="/ppc"><h3>Pay-Per-Click Marketing</h3></Link>
                                    <p>  We are specialized in providing high-quality Pay-Per-Click (PPC) marketing services to businesses looking to increase their online visibility and drive targeted traffic to their {expandedCards.includes(6) ? (                      <>
                                      Websites. With our expertise in keyword research, ad copy creation, and campaign optimization, we help our clients maximize their return on investment by ensuring that their ads are reaching the right audience at the right time. We work closely with each client to develop a customized strategy that aligns with their goals and budget, and we continuously monitor and adjust their campaigns to ensure optimal performance. Let us help you take your online advertising to the next level and grow your business through PPC marketing.
                                    </>
                          ) : (
                            <>
                              <span id="dots"></span>
                              <span className="more">...</span>
                            </>
                          )}
                            </p>
                            <button onClick={() => toggleText(6)} className="toggle-btn">
                          {expandedCards.includes(6) ? 'Read less' : '....Read more'}
                        </button>
                                </div>
                            </div>
                        </div>
                   </div> 
                   </Zoom>
                   <Zoom>
                   <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                        <div className="card-flyer">
                            <div className="text-box">
                                <div className="image-box">
                                     <img src="images/imgbox6.webp" alt="Website Development" />
                                </div>
                                <div className="text-container">
                                       <img className="smimgsrv_1" src="images/ssm6.webp" alt="Customized Templates"/>
                                       <Link to="/Website-template"><h3>Template</h3></Link>
                                   <p>Our team of experienced designers and developers work closely with clients to create customized Templates that not only look great but also drive engagement and conversions. {expandedCards.includes(7) ? (                                <>
                                   We understand that every business has unique needs, which is why we offer a wide range of template options to choose from or can create a completely custom design tailored to your specific requirements. Our Templates are optimized for all devices and email clients, ensuring that your messages always look professional and polished. With our email template services, you can streamline your communication efforts and take your Email Marketing to the next level.
                                   </>
                          ) : (
                            <>
                              <span id="dots"></span>
                              <span className="more">...</span>
                            </>
                          )}
                            </p>
                            <button onClick={() => toggleText(7)} className="toggle-btn">
                          {expandedCards.includes(7) ? 'Read less' : '....Read more'}
                        </button>
                                </div>
                            </div>
                        </div>
                </div> 
                </Zoom>      
              </div>
            </div>            
          </div>
        </div>
      </section>

<section>
        <div className="container-fluid">
          <div className="srv_sec lng_rckrz servcemx_wdth2 pt-5 mt-90 text-center pb-90">
            <h2>Our<span style={{color: '#223464'}}> Abundant Experience</span> </h2>
            <p>To truly thrive in the digital age, today's companies must rapidly adapt their organization while providing compelling and unique offerings that drive significant customer satisfaction. Our diverse teams possess extensive experience in <Link to="/digital-marketing-agency">Digital Marketing</Link>, <Link to="/web-design"> Web Designing</Link>, and <Link to="/web-development"> Web Development</Link>, utilizing the latest programming languages and frameworks. We excel at developing solutions for popular platforms such as Shopify, WooCommerce, Magento, WordPress, and Drupal.<br />
              Effectively implementing digital strategies across the front, middle, and back-office IT environments is crucial for maintaining relevance in today's market. We assist our clients in seamlessly integrating their business and technology architectures, driving growth, and launching strategies aimed at gaining a competitive advantage in the digital landscape.<br />
			  
              <Link to="/contact"><span style={{textDecoration: 'underline', color: '#223464', cursor: 'pointer'}}>Contact Us</span></Link> today for consultation. Let's embark on the journey to shape the digital future of your business together.</p>
          </div>
        </div>
</section>
        </div>
    );
}

export default Services;