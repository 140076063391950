import Testimonial from '../components/testimonial';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; 
import Zoom from 'react-reveal/Zoom';
import { Fade } from 'react-reveal';
import React, { useEffect } from 'react';

function Webdevelopment(){
  const testimonialProps1 = {
    title: "Ongoing Website Development",
    imageSrc: "images/wb_dev1.png",
    testimonialText: "Uniwork Technologies is amazing to work with! Incredibly talented, professional, and punctual. I recommend Uniwork Technologies completely and unreservedly and will definitely use them again for future tasks.",
  };

  const testimonialProps2 = {
    title: "Install Slider Revolution plugin into wordpress.",
    imageSrc: "images/wb_dev2.png",
    testimonialText: "I Need someone to go on FTP for my wordpress site and delete an (folder already exists) problem and Uniwork Technologies is highly efficient, making everything extremely smooth. I will hire them again for sure.",
  };

  const testimonialProps3 = {
    title: "Need web developer to do quick modification with LMS plugin",
    imageSrc: "images/wb_dev3.png",
    testimonialText: "Uniwork Technologies has consistently provided 100% solutions to our company's issues. We highly recommend them for any kind of WordPress development work. Their problem-solving skills are amazing.",
  };
  const testimonialProps4 = {
    title: "Re-design an offer page and implement in shopify",
    imageSrc: "images/wb_dev4.png",
    testimonialText: "Diksha and her team have done an amazing job for our company! Their work is outstanding and has exceeded our expectations. We're thrilled with her contribution to our project!",
  };
    useEffect(() => {
      window.scrollTo(0, 0); // Ensure the page starts at the top when the component mounts.
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Smoothly scroll to the top
    };
  
  
    return(
        <div className="mainn">
     <Helmet>
        <title>
        Web Development Services | Custom Website Solutions</title>
<meta name='description' content='Choose Uniwork Technologies for professional Web Development services. Our expert team crafts custom websites for maximum online impact, tailored to your needs.' />
      </Helmet>
<section style= {{paddingTop: '1px' }}>
	<div className="container-fluid">
  <Fade up>
     <div className="websec_1 servcemx_wdth2 sec_3  mt-90 text-center pb-40">
                <h1>Web<span style={{ color: '#223464' }}> Development</span> </h1>
<p>Our company offers professional Web Development services to help businesses establish a strong online presence. Our experienced team of web developers specializes in creating custom websites that are tailored to meet the unique needs of our clients. We use the latest Web Development technologies and best practices to ensure that our clients' websites are not only visually appealing but also highly functional, responsive, and user-friendly. From E-Commerce platforms to content management systems, our Web Development services cover a wide range of solutions to help our clients achieve their business objectives. </p>
</div>
</Fade>
</div>
</section>

<section>
	<div className="pb-90"> 
  <img src="images/web1.webp" width="100%" alt="Web Development Technologies" />
</div>
</section>

<section>
	<div className="container-fluid">
     <div className=" websec_3 sec_3  mt-3 text-center ">
                <h2>Our<span style={{ color: '#223464' }}> Web Development Services</span> </h2>

<div className="container mt-5">
  <div className="row">
    <Zoom>
    <div className="col">
      <div className="box_1 box_3" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/dp1.webp" width="90" alt="ReactJs & HTML5 Development" />
        <h3>ReactJs & HTML5 Development</h3>
        <p>We have the expertise to design and develop cross platform compatible rich internet applications. Our team works closely with our customers to understand the requirements and then define a User Interface which is rich in navigation, visualization and interaction yet simple in terms of layout and usability.</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
    <div className="col">
      <div className="box_1" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/dp2.webp" width="90" alt="ECommerce Solutions Development" />
        <h3>eCommerce Solutions Development</h3>
        <p>With more and more businesses going online, the E-Commerce marketplace has become highly competitive. A standard off-the-shelf solution does not give the flexibility you require to enhance conversions and drive profit. Experienced UNIWORK E-Commerce design specialists and developers can create a custom E-Commerce solution that works just right for your customers and maximizes conversions.</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
    <div className="col">
      <div className="box_1 " style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/dp3.webp" width="90" alt="Framework" />
        <h3>Framework</h3>
        <p>Framework becomes intimately involved in the management and control of each property as we believe important information is often lost due to the finer details not being understood or acted upon. In addition, our employees and senior management regularly visit all properties to ensure that the management and administration of every site is handled with equal attention.</p>
      </div>
    </div>
    </Zoom>
  </div>  
  <div className="ttl_ss_des"><p>Reach out to <Link to="/">Uniwork Technologies</Link> today to explore how our <Link to="/web-development" onClick={scrollToTop}>Website Development Services</Link> can enhance your online presence and attract more traffic to your website. We also offer a wide range of other services to meet all your digital needs, including <Link to="/website-maintenance">Web Maintenance</Link>, <Link to="/graphic-design">Graphic Designing</Link>, <Link to="/web-design">Web Designing</Link>, and <Link to="/digital-marketing-agency">Digital Marketing Services</Link>. Contact us now, and let's make your online presence stand out and cover all your digital requirements!</p></div>
</div>


            </div>
</div>
</section>
<section className="bg-light services_callsec mt-90">
      <div className="container">
        <div className="row align-items-center">  
          <div className="col-md-6">
            <div>
              <h2>Schedule a call</h2>
              <h2>now with our</h2>
              <h2>Web Development</h2>
              <h2>expert!</h2>
            </div>
            <div className='btn_areaservsec'>
              <img src='images/right_arrow.png'/>
              <button className="btn_services shed_call">
      <Link to="https://wa.me/06239452423" target='_blank'>Contact Us</Link>
    </button>
            </div>
          </div>
          <div className="col-md-6">
            {/* <img src="images/smiling-woman-headset-presentation-something.png" alt="Background Image" className="img-fluid" /> */}
          </div>
        </div>
      </div>
    </section>
    <div>
    <section className="mt-90 pb-90 abt_last7 mx-4" aria-label='Ut back'>
<div className="mx_wdth2 sec_3 mt-90 pb-90 text-center">
<p className="svr_12 pt-3">- TESTIMONIAL -</p>
<h2>See What People Say<span style={{ color: '#223464' }}> About Us</span></h2>
</div>
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner abt-tsti1">
          <div className="carousel-item active p-4">
            <Testimonial {...testimonialProps1} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps2} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps3} />
          </div>   
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps4} />
          </div>      
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"><img src="images/lft.webp" alt="Breadcrumb Left"/></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"><img src="images/rgt.webp" alt="Breadcrumb Right"/></span>
      <span className="visually-hidden">Next</span>
    </button>
      </div>
      </section>
      </div>
        
        </div>
    );
}

export default Webdevelopment;