import React, { useEffect } from 'react';
const LandingPage = () => {
    useEffect(() => {
        // const counters = document.querySelectorAll('.counter');

        // counters.forEach(counter => {
        //     counter.innerText = '0';

        //     const updateCounter = () => {
        //         const target = +counter.getAttribute('data-target');
        //         const current = +counter.innerText;
        //         const increment = target / 200;

        //         if (current < target) {
        //             counter.innerText = `${Math.ceil(current + increment)}`;
        //             setTimeout(updateCounter, 10);
        //         } else {
        //             counter.innerText = target;
        //         }
        //     };

        //     updateCounter();
        // });

        function toggleText(element) {
            const visibleText = element.querySelector('.visible-text');
            const hiddenText = element.querySelector('.hidden-text');
        
            if (visibleText.style.display !== 'none') {
                visibleText.style.display = 'none';
                hiddenText.style.display = 'block';
            } else {
                visibleText.style.display = 'block';
                hiddenText.style.display = 'none';
            }
        }
    }, []);

    return (
        <>
            {/* Header Section */}
            <section>
                <div className="header_area">
                    <a href="#"><img className="lp_logo" src="images/d-d-logo.png" alt="logo" /></a>
                    <div className="mail_call_btns">
                        <div className="mail">
                            <img src="images/mail_svgrepo.png" alt="mail" />
                            <a href="#">uniworkitsolution@gmail.com</a>
                        </div>
                        <div className="phone-calling">
                            <img src="images/phone-calling_svgrepo.png" alt="phone" />
                            <a href="#">+91 6239452423</a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Banner Section */}
            <section className="banner_sec">
                <div className="conti_1">
                <div className="column_f_banner">
            <div className="text_area">
                <h1>Comprehensive Web Design & Development Services</h1>
				<span className="stg_1">Strategic Design, Flawless Execution</span>
                <p>Choose <span className="bnt_1">Uniwork Technologies</span> for exceptional web design, support, maintenance, and responsive customer service to keep your website running smoothly.</p>
				<div className="aftrbnr">
				<button className="bnbtn_1">
				<a href="">Explore Our Stunning Websites!</a>
				</button>
		</div>
				<img className="cb1" src="images/cb1.png" />
            </div>
            <div className="form_area">		
                <div className="dotted_1">
                </div>			
                <div className="mob_form">				
				<div className="lwt_1">
                    <h4>Let's Work Together</h4>
                <p>*Please fill out all fields.</p>
                    <form className="mbfom1_1">
                    <label for="name">Name*</label>
                    <input type="text" id="name" name="name" />

                    <label for="phone">Phone Number*</label>
                    <input type="tel" id="phone" name="phone" />

                    <label for="email">Email*</label>
                    <input type="email" id="email" name="email" />

 <label>Services*</label>
 <div className="srvd_1">
                    <input type="checkbox" id="web_designing" name="services" value="Web Designing"/>
                    <label for="web_designing">Web Designing</label>

                    <input type="checkbox" id="web_development" name="services" value="Web Development"/>
                    <label for="web_development">Web Development</label>

                    <input type="checkbox" id="graphic_designing" name="services" value="Graphic Designing"/>
                    <label for="graphic_designing">Graphic Designing</label>
                  </div>
                    <label for="message">Message</label>
                    <textarea id="message" name="message"></textarea>
                    
                    <button className="sb1-1" type="submit">Send Message</button>
                </form>
				</div>

				</div>
            </div>
        </div>
                </div>
            </section>

            {/* Counter Section */}
            <section className="count_1">
                <div className="contanier_landing">
                <div className="counter-container">
        <div className="counter-box">
            <div className="counter" data-target="8">0</div>
            <p><span className="spn_12">+</span><span className="spn_13">YEARS OF EXPERIENCE</span></p>
        </div>
        <div className="counter-box">
            <div className="counter" data-target="180">0</div>
            <p><span className="spn_12">+</span><span className="spn_13"> HAPPY CLIENTS</span></p>
        </div>
        <div className="counter-box">
            <div className="counter" data-target="200">0</div>
           <p><span className="spn_12">+</span> <span className="spn_13">PROJECTS COMPLETED</span></p>
        </div>
        <div className="counter-box">
            <div className="counter" data-target="1250">0</div>
            <p><span className="spn_12">+</span><span className="spn_13">HOURS OF SUPPORT</span></p>
        </div>
    </div>
                </div>
            </section>

            {/* Two-column Section */}
            <section className="ct5">
                <div className="contanier_landing">
                <div className="two-column-section">
        <div className="image-column">
            <img src="images/raloha.png" alt="Description of image"/>
        </div>
        <div className="text-column">
            <h2>We Create Stunning Websites That Drive Revenue</h2>
            <p>At <span className="uni_1">Uniwork Technologies,</span> we pride ourselves on our ability to create more than just visually appealing websites; we create digital experiences that yield tangible results. Our dedicated team of experts blends creativity with the latest technological advancements to develop websites that not only catch the eye but also drive meaningful outcomes for your business.</p>
			
			<p>With <span className="uni_1">Uniwork Technologies,</span> you can trust that your website is in capable hands. Let us help you unlock the full potential of your online presence and take your business to new heights.</p>
        </div>
		</div>
                </div>
            </section>

            {/* Service Portfolio Section */}
            <section className="pt_12">
                <div className="contanier_landing">
                <h2>Our Service Portfolio</h2>
	 <div className="three-column-section">
	 
        <div className="column-box" onclick="toggleText(this)">
            <div className="visible-text">
                <img className="mdlg_1" src="images/WDS.png" alt="Logo 1"/>
                <h3 className="title">Web Designing Services</h3>
				<img src="images/wline.png"/>
            </div>
            <div className="hidden-text">
			<h4>Web Design:</h4>
			<p>A visually appealing website is essential for capturing the attention of your target audience. Our talented designers will work closely with you to craft a custom design that reflects your brand identity and engages your visitors from the moment they land on your site. We ensure that the design is not only aesthetically pleasing but also optimized for search engines to enhance your online visibility.</p>
			<ul className="srvc_8">
			<p>Key Points:</p>
			<li><img className="nik_1" src="images/nik.png"/>Responsive website design</li>
			<li><img className="nik_1" src="images/nik.png"/>User interface (UI) and user experience (UX) design</li>
			<li><img className="nik_1" src="images/nik.png"/>Website redesign and optimization</li>
			<li><img className="nik_1" src="images/nik.png"/>Landing page design</li>
			</ul>
			</div>
        </div>
        <div className="column-box" onclick="toggleText(this)">
            <div className="visible-text">
                 <img className="mdlg_1" src="images/wbdb.png" alt="Logo 1"/>
                <h3 className="title">Web Development Services</h3>
				<img src="images/wline.png"/>
            </div>
              <div className="hidden-text">
			<h4>Web Development:</h4>
			<p>Are you ready to establish a strong online presence for your business? Our experienced team of developers uses the latest technologies and industry best practices to create stunning, responsive, and SEO-friendly websites. Our websites not only look great but also function seamlessly across all devices, ensuring an optimal user experience. Whether you need a simple informational site or a complex e-commerce platform, we've got you covered.</p>
			<ul className="srvc_8">
			<p>Key Points:</p>
			<li><img className="nik_1" src="images/nik.png"/>Custom website development</li>
			<li><img className="nik_1" src="images/nik.png"/>Content management system (CMS) integration</li>
			<li><img className="nik_1" src="images/nik.png"/>E-commerce solutions</li>
			<li><img className="nik_1" src="images/nik.png"/>Website maintenance and support</li>
			</ul>
			</div>
        </div>
        <div className="column-box" onclick="toggleText(this)">
            <div className="visible-text">
                <img className="mdlg_1" src="images/gds.png" alt="Logo 1"/>
                <h3 className="title">Graphic Designing Services</h3>
				<img src="images/wline.png"/>
            </div>
               <div className="hidden-text">
			<h4>Graphic Design:</h4>
			<p>Stand out from the competition with captivating graphic design elements that leave a lasting impression. From logo design and branding to marketing materials and social media graphics, our designers will help bring your vision to life with creativity and precision, ensuring consistency across all your branding materials.</p>
			<ul className="srvc_8">
			<p>Key Points:</p>
			<li><img className="nik_1" src="images/nik.png"/>Logo design and branding</li>
			<li><img className="nik_1" src="images/nik.png"/>Print design (business cards, flyers, brochures, etc.)</li>
			<li><img className="nik_1" src="images/nik.png"/>Social media graphics</li>
			<li><img className="nik_1" src="images/nik.png"/>Illustration and vector art</li>
			</ul>
			</div>
        </div>
		</div>
                </div>
            </section>

            {/* Images Section */}
            <section className="images-section1">
                <div className="contanier_landing">
                    {/* Images section content */}
                </div>
            </section>
        </>
    );
};

export default LandingPage;
