import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Fade } from 'react-reveal';

const Header = () => {
  useEffect(() => {
    const drp123 = document.getElementById('drp_123');
    const drpLinks = drp123.querySelectorAll('a');
    const bfr12 = document.getElementById('bfr_12');
    const servicesLink = document.getElementById('bfr_121'); // Updated to use the new ID
    const noDropdownLink11 = document.getElementById('no_drp11');
    const noDropdownLink12 = document.getElementById('no_drp12');
    const noDropdownLink13 = document.getElementById('no_drp13');

    const closeDropdown = () => {
      drp123.style.display = 'none';
    };

    const displayDropdown = () => {
      drp123.style.display = 'block';
    };

    const closeOnLeave = () => {
      drp123.style.display = 'none';
    };

    const isMobileOrTab = () => {
      return window.innerWidth <= 768; // Adjust the breakpoint as per your needs
    };

    const toggleDropdown = () => {
      if (isMobileOrTab()) {
        if (drp123.style.display === 'block') {
          drp123.style.display = 'none';
        } else {
          drp123.style.display = 'block';
        }
      }
    };

    const closeOnOtherLinkHover = (event) => {
      if (drp123.style.display === 'block' && event.target !== servicesLink && !drp123.contains(event.target)) {
        closeDropdown();
      }
    };

    const closeOnNoDropdownLinkHover = () => {
      if (drp123.style.display === 'block') {
        closeDropdown();
      }
    };

    drpLinks.forEach((link) => {
      link.addEventListener('click', closeDropdown);
      link.addEventListener('mouseenter', closeOnOtherLinkHover); // Close on hover of other links
    });

    bfr12.addEventListener('mouseenter', displayDropdown);
    drp123.addEventListener('mouseleave', closeOnLeave);

    servicesLink.addEventListener('mouseenter', () => {
      if (!isMobileOrTab()) {
        displayDropdown();
      }
    });

    servicesLink.addEventListener('click', () => {
      if (isMobileOrTab()) {
        if (drp123.style.display === 'block') {
          drp123.style.display = 'none';
        } else {
          drp123.style.display = 'block';
        }
      } else {
        closeDropdown();
      }
    });

    noDropdownLink11.addEventListener('mouseenter', () => {
      if (!isMobileOrTab()) {
        closeDropdown();
      }
    });

    noDropdownLink12.addEventListener('mouseenter', () => {
      if (!isMobileOrTab()) {
        closeDropdown();
      }
    });

    noDropdownLink13.addEventListener('mouseenter', () => {
      if (!isMobileOrTab()) {
        closeDropdown();
      }
    });

    // Clean up the event listeners when the component unmounts
    return () => {
      drpLinks.forEach((link) => {
        link.removeEventListener('click', closeDropdown);
        link.removeEventListener('mouseenter', closeOnOtherLinkHover);
      });
      bfr12.removeEventListener('mouseenter', displayDropdown);
      drp123.removeEventListener('mouseleave', closeOnLeave);
      servicesLink.removeEventListener('mouseenter', () => {
        if (!isMobileOrTab()) {
          displayDropdown();
        }
      });
      servicesLink.removeEventListener('click', () => {
        if (isMobileOrTab()) {
          if (drp123.style.display === 'block') {
            drp123.style.display = 'none';
          } else {
            drp123.style.display = 'block';
          }
        } else {
          closeDropdown();
        }
      });
      noDropdownLink11.removeEventListener('mouseenter', () => {
        if (!isMobileOrTab()) {
          closeDropdown();
        }
      });
      noDropdownLink12.removeEventListener('mouseenter', () => {
        if (!isMobileOrTab()) {
          closeDropdown();
        }
      });
      noDropdownLink13.removeEventListener('mouseenter', () => {
        if (!isMobileOrTab()) {
          closeDropdown();
        }
      });
    };
  }, []);

  
    return(
        <nav className="navbar navbar-expand-lg">
    <div className="container-fluid">
      <a className="navbar-brand" href="/">
        <img src="Images/logo.png" alt="Uniwork Technologies Logo (landscape)" />
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul className="navbar-nav ms-5">
          <li className="nav-item">
          <Link id='no_drp11' className="nav-link" to="/">Home</Link>
          </li>
          <li  className="nav-item">
            <Link id='no_drp12' className="nav-link" to="/about">About</Link>
          </li>
          <li id='bfr_12' className="bfr_12 nav-item services"> 
          <Link id='bfr_121' className="nav-link" to="/services">Services</Link>
          <Fade up>
            <div id="drp_123" className="drp_123" aria-labelledby="megaMenu">
              <div className="row">
                <div className="col-md-3 text-center srv_12">
                  <p>Our Services</p>
                </div>
                <div className="col-md-9">
                  <div className="container">
                    <div className="row drpbdr">
                      <div className="col-md-3 col-6">
                        <div className="dropdown-item-image text-center">
                        <Link to="/web-design"> <img src="images/mega1.webp" alt="Best Web Designing Services" /> </Link>
                          <p className="dropdown-item-heading">
                            <Link to="/web-design">Web Designing</Link></p>
                        </div>
                      </div>
                      <div className="col-md-3 col-6">
                        <div className="dropdown-item-image text-center mgm_2">
                        <Link to="/web-development"><img src="images/mega2.webp" alt="Professional Web Development Services" /></Link>
                          <p className="dropdown-item-heading">
                          <Link to="/web-development">Web Development</Link></p>
                        </div>
                      </div>
                      <div className="col-md-3 col-6">
                        <div className="dropdown-item-image text-center">
                        <Link to="/digital-marketing-agency"><img src="images/mega3.webp" alt="Digital Marketing" /></Link>
                          <p className="dropdown-item-heading">
                          <Link to="/digital-marketing-agency">Digital Marketing</Link></p>
                        </div>
                      </div>
                      <div className="col-md-3 col-6 text-center">
                        <div className="dropdown-item-image mg_4">
                        <Link to="/graphic-design"> <img src="images/mega41.webp" alt="Graphic Designing" /></Link>
                          <p className="dropdown-item-heading">
                          <Link to="/graphic-design">Graphic Designing</Link></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container mg2">
                    <div className="row drpbdr">
                      <div className="col-md-3 col-6">
                        <div className="dropdown-item-image text-center">
                        <Link to="/website-maintenance"><img src="images/mega5.webp" alt="Web Maintenance" /></Link>
                          <p className="dropdown-item-heading">
                            <Link to="/website-maintenance">Website Maintenance</Link></p>
                        </div>
                      </div>
                      <div className="col-md-3 col-6">
                        <div className="dropdown-item-image text-center">
                        <Link to="/website-template"><img src="images/mega6.webp" alt="Custom Design Templates" /></Link>
                          <p className="dropdown-item-heading">
                          <Link to="/website-template">Template</Link></p>
                        </div>
                      </div>
                      <div className="col-md-3 col-6">
                        <div className="dropdown-item-image text-center">
                        <Link to="/website-analytics"> <img src="images/mega7.webp" alt="Web Analytics Services" /></Link>
                          <p className="dropdown-item-heading">
                          <Link to="/website-analytics">Website Analytics</Link></p>
                        </div>
                      </div>
                      <div className="col-md-3 col-6 text-center">
                        <div className="dropdown-item-image">
                        <Link to="/ppc"> <img src="images/mega8.webp" alt="Pay-Per-Click Marketing" /> </Link>
                          <p className="dropdown-item-heading">
                          <Link to="/ppc">Pay-Per-Click Marketing</Link></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </Fade>
          </li>
          <li className="nav-item">
            <Link id='no_drp13' className="nav-link" to="portfolio">Portfolio</Link>
          </li>
        </ul>
        <div className="srch-1">
          <Link className="search_1" to="#">
            <img src="Images/search.webp" alt="search" />
          </Link>
          <Link to="/contact"> <button className="hdrbtn_1" id='cont_us_btn'>Contact Us</button></Link>
        </div>
      </div>
    </div>
  </nav>

    );
}

export default Header;