import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/canonical_rs'; 
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Template from "./pages/website-template";
import Services from "./pages/services";
import Webdesign from "./pages/web-design";
import Webdevelopment from "./pages/web-development";
import Graphicdesign from "./pages/graphic-design";
import Portfolio from "./pages/portfolio";
import Payperclick from "./pages/ppc";
import Seo from "./pages/seo";
import Webanalytics from "./pages/website-analytics";
import Maintenance from "./pages/website-maintenance";
import Digital from "./pages/digital-marketing-agency";
import Marketing from "./pages/online-marketing-services";
import Onpageseo from "./pages/on-page-seo";
import Designingdevlopmentservices from "./pages/designing-devlopment-services";

function App() {
  return (
    <div>

        <Header />
        
        <Layout> 
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="website-template" element={<Template />} />
            <Route path="services" element={<Services />} />
            <Route path="graphic-design" element={<Graphicdesign />} />
            <Route path="web-design" element={<Webdesign />} />
            <Route path="web-development" element={<Webdevelopment />} />
            <Route path="ppc" element={<Payperclick />} />
            <Route path="website-analytics" element={<Webanalytics />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="website-maintenance" element={<Maintenance />} />
            <Route path="contact" element={<Contact />} />
            <Route path="seo" element={<Seo />} />
            <Route path="digital-marketing-agency" element={<Digital />} />
            <Route path="online-marketing-services" element={<Marketing />} />
            <Route path="on-page-seo" element={<Onpageseo />} />
            <Route path="designing-devlopment-services" element={<Designingdevlopmentservices />} />
            </Routes>
        </Layout>

        <Footer />

    </div>
  );
}

export default App;
