import Testimonial from '../components/testimonial';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; 
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import React, { useEffect } from 'react';

function Webanalytics(){
  const testimonialProps1 = {
    title: "Google Analytics Conversion Tracking Setup ",
    imageSrc: "images/dte_11.png",
    testimonialText: "Uniwork Technologies is a fantastic company to work with! They are incredibly reliable and highly knowledgeable. They assisted us with a wide range of tasks, from setting up a new Google Data Studio to meticulously verifying that our attribution tracking across multiple clients was accurate. We couldn't have asked for better support. I wholeheartedly recommend Uniwork Technologies for their exceptional expertise and service.",
  };

  const testimonialProps2 = {
    title: "Google Analytics Setup & Report",
    imageSrc: "images/dte_12.png",
    testimonialText: "Uniwork Technologies: Your Remarkable Project Partner: Prompt responses and timely deliveries. We'll certainly choose them again for future projects.",
  };

  const testimonialProps3 = {
    title: "Google Analytics and Google Tag Manager experts are needed.",
    imageSrc: "images/dte_13.png",
    testimonialText: "We worked with Uniwork Technologies, and their excellent communication, responsiveness, and extensive knowledge of all things Google were highly impressive. The work done in setting up our GA and GTM for our Website was exceptional. We will definitely turn to Uniwork Technologies again for SEO and Google Adwords advice and implementation as our Website gains traction.",
  };

  const testimonialProps4 = {
    title: "Amber Industrial Services - Lead Gen tracking setup Website optimization",
    imageSrc: "images/dte_14.png",
    testimonialText: "Thanks to Uniwork Technologies and their team, we witnessed a significant improvement in our SEO ranking. Our overall page speed received a substantial boost, resulting in a noticeable increase in organic search traffic. We are looking forward to continued success with Uniwork Technologies.",
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Ensure the page starts at the top when the component mounts.
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smoothly scroll to the top
  };
    return(
        <div className="mainn">
 <Helmet>
        <title>
        Website Analytics Services: Monitor, Analyze, & Optimize</title>
<meta name='description' content='Unlock valuable insights with Uniwork Technologies Website analytics services. Analyze web traffic, conversions & strategies with our data analytics expertise. (160/160 Chars)' />
      </Helmet>
<section style={{ paddingTop: '1px' }}>
<div className="container-fluid">
  <Fade up>
     <div className="websec_1 servcemx_wdth2 sec_3  mt-90 text-center pb-40">
                <h1>Web<span style={{ color: '#223464' }}> Analytics</span> </h1>
<p>Our company provides Web Analytics services to help businesses Measure, Analyze, and Optimize their website performance. Our services include Website Traffic Analysis, Conversion Tracking, User Behavior Analysis, A/B Testing, and Custom Reports. We use the latest technology and techniques to provide accurate, actionable data that can help you improve your online presence and drive business growth.</p>
</div>
</Fade>
</div>
</section>

<section>
<div class="pb-90"> 
  <img src="images/analytcsbnr.webp" width="100%" alt="Our Web Analytics Services"/>
</div>
</section>

<section>
<div className="container-fluid">
     <div className=" websec_3 sec_3  mt-3 text-center ">
                <h2>Our<span style={{ color: '#223464' }}> Web Analytics Services</span> </h2>

<div className="container mt-5">
  <div className="row">
    <Zoom>
    <div className="col">
      <div className="box_1 analyticsbox_2" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/anlytcs1.webp" width="90" alt="Data-Driven Decisions"/>
       <h3>Data-Driven Decisions</h3>
        <p>Unleash the Power of Analytics to Optimize Your Website" - Our Web Analytics services provide valuable insights into your Website's Performance, allowing you to make informed decisions and improve user experience.</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
    <div className="col">
      <div className="box_1 analyticsbox_2" style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/anlytcs2.webp" width="90" alt="Boost Your ROI"/>
        <h3>Boost Your ROI</h3>
        <p>Harness the Full Potential of Your Marketing Efforts with Analytics" - Our Web Analytics experts help you analyze and optimize your Digital Marketing strategies to maximize your ROI and achieve your business goals.</p>
      </div>
    </div>
    </Zoom>
    <Zoom>
    <div className="col">
      <div className="box_1 " style={{ backgroundColor: '#F2F1F1' }}>
      	<img src="images/anlytcs3.webp" width="90" alt="Real-Time Insights"/>
        <h3>Real-Time Insights</h3>
        <p>Stay Ahead of the Competition with Up-to-the-Minute Data" - With our real-time Web Analytics services, you get access to real-time data on user behavior, Website performance, and more. Stay ahead of the competition by making timely decisions to improve your online presence.</p>
      </div>
    </div>
    </Zoom>
  </div>
  <div className="ttl_ss_des"><p>Want to tap into valuable data insights to enhance your web performance?<Link to="/"> Uniwork Technologies</Link> offers <Link to="/Website-analytics" onClick={scrollToTop}>Web Analytics Services</Link> to provide the insights you need. If you're interested in exploring other services such as <Link to="/seo">SEO</Link>, SMO <Link to="/ppc">PPC, </Link>and more. We're here to assist. Let us know your goals and allow us to be your trusted <Link to="/digital-marketing-agency">Digital Marketing Agency.</Link></p></div>
</div>


            </div>
			</div>
</section>
<section className="bg-light services_callsec mt-90">
      <div className="container">
        <div className="row align-items-center">  
          <div className="col-md-6">
            <div>
              <h2>Schedule a call</h2>
              <h2>now with our</h2>
              <h2>Web Analytics</h2>
              <h2>expert!</h2>
            </div>
            <div className='btn_areaservsec'>
              <img src='images/right_arrow.png'/>
              <button className="btn_services shed_call">
      <Link to="https://wa.me/06239452423" target='_blank'>Contact Us</Link>
    </button>
            </div>
          </div>
          <div className="col-md-6">
            {/* <img src="images/smiling-woman-headset-presentation-something.png" alt="Background Image" className="img-fluid" /> */}
          </div>
        </div>
      </div>
    </section>
    <div>
    <section className="mt-90 pb-90 abt_last7 ssx_str mx-4" aria-label='Ut back'>
<div className="mx_wdth2 sec_3 mt-90 pb-90 text-center">
<p className="svr_12 pt-3">- TESTIMONIAL -</p>
<h2>See What People Say<span style={{ color: '#223464' }}> About Us</span></h2>
</div>
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner abt-tsti1">
          <div className="carousel-item active p-4">
            <Testimonial {...testimonialProps1} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps2} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps3} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps4} />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"><img src="images/lft.webp" alt="Breadcrumb Left"/></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"><img src="images/rgt.webp" alt="Breadcrumb Right"/></span>
      <span className="visually-hidden">Next</span>
    </button>
      </div>
      </section>
      </div>
            
            </div>
    );
}

export default Webanalytics;