import { Link } from 'react-router-dom';
import Testimonial from '../components/testimonial';
import { Helmet } from 'react-helmet'; 
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
function About(){
    return(
        <div className ="mainn">
             <Helmet>
        <title>
        Learn More About Uniwork Technologies: Who We Are</title>
<meta name='description' content='Our experts work with you to create strategies that reach your goals. Learn more about what we do and get the best results with Uniwork Technologies today.' />
      </Helmet>
            <section className="row ab_1 sec_1 m-0" aria-label='About Us- Uniwork Technologies'> 
	<div className="mxwdth_125 abt_flx ">
    <div className="websec_1  col-sm-6 bnrtxt_1 abtxt_1">
<h1><span style={{ color: '#fff' }}>About Us</span></h1>
    </div>
    <Fade right>
    <div className="col-sm-6 banner_img1 center">
<img src="Images/topbnr.webp" alt="We are Uniwork" /> 
</div>
</Fade>
    </div>
  </section>

  <section>
<div className="container-fluid">
<div className="row inr6 pb-5 align-items-center">

<div className="col-sm-6 col-xxl-7  text-start img_cmplogo">
	<img src="images/ab2.webp" width="80%" alt="Our Story- Uniwork Technologies" />
</div>
<Fade up>
<div className="col-sm-6 col-xxl-5  mt-4">
	<h2>Our<span style={{ color: '#223464' }}> Story</span> </h2>
	<p className='lng_rckrz'>At <strong>Uniwork Technologies,</strong> we're passionate about empowering businesses with innovative digital solutions. Founded in 2015, our team of over 20 experts in Software Development, Data Analytics, and Artificial Intelligence has been dedicated to helping businesses thrive in the digital age.
Our commitment to innovation, customer satisfaction, and excellence has driven our success. We believe that every business has the potential to transform itself with the right technology, and we're here to help them unlock that potential.<br />
Join <Link to="/">Uniwork Technologies</Link> on our journey to push the boundaries of what's possible in the digital world and help businesses achieve their goals. Our passion for excellence drives us to deliver exceptional results for our clients. (We're passionate about our work and client success.)</p>
</div>
</Fade>
</div>
<div className="row mt-5 pb-5 d-blk dv8yr_1">
<Zoom>
      <div className="col text-center">       
        <div className="custom-border">
         <img src="images/l8-1.webp" width="30px" alt="8+ Years Of Experience"/>
         <p class="exp_41">8+</p>
       <p>YEARS OF EXPERIENCE</p>
        </div>        
      </div>
      </Zoom>
      <Zoom>
      <div className="col text-center">        
        <div className="custom-border">
       <img src="images/l8-2.webp" width="30px" alt="200+ Projects Completed"/>
       <p class="exp_41">200+</p>
       <p>PROJECTS COMPLETED</p>
        </div>        
      </div>
      </Zoom>
      <Zoom>
      <div className="col text-center">       
        <div className="custom-border">
         <img src="images/l8-3.webp" width="30px" alt="Happy Clients"/>
         <p class="exp_41">90+</p>
       <p>HAPPY CLIENTS</p>
        </div>        
      </div>
      </Zoom>
      <Zoom>
      <div className="col text-center">        
        <div className="custom-border">
        <img src="images/l8-4.webp" width="30px" alt="Support Hours"/>
        <p class="exp_41">1250</p>
       <p>HOURS OF SUPPORT</p>
        </div>        
      </div>
      </Zoom>
    </div>
</div>
</section>

<section>
<div className="container-fluid">
	<div className="mx_wdth2 sec_3 mt-90 text-center">
		<p class="svr_12">- To provide the best IT services -</p>
				<h2>About <span style={{ color: '#223464' }}>Founders</span> </h2>
</div>
<div className="row pt-5 inr6 pb-5 align-items-center">

<div className="col-sm-6 col-xl-5 text-start">
	<img src="images/d1.webp" width="70%" alt="Diskha Rani" />
</div>
<Fade right>
<div className="col-sm-6 founders_pp col-xl-7 mt-4">	
	<p>I am passionate about helping businesses create a strong online presence that reflects their unique brand identity. With a wealth of experience in the industry, I have honed my skills in <Link to="/web-design">Web Design</Link> and <Link to="/web-development">Development</Link>, User Experience, and Digital Marketing, and I am dedicated to delivering high-quality solutions that drive results. Whether it's designing a new website, improving an existing one, or developing custom Web Applications, I have the knowledge and expertise to deliver exceptional results. I understand that every business is unique, and I work closely with my clients to understand their specific needs and objectives, and create custom solutions that meet their requirements. With a focus on innovation, creativity, and excellence, I am committed to helping businesses achieve their online goals and stay ahead of the competition.</p>
</div>
</Fade>
</div>

<div className="row inr6 mt-90 align-items-center">	
<Fade left>
<div className="col-sm-6 col-xl-7 founders_pp order-xl-1 order-2 mt-xl-0 mt-4">	
	<p>I am passionate about helping businesses maximize their online presence and achieve their <Link to="/digital-marketing-agency">Digital Marketing</Link> goals. With a deep understanding of Google Ads and <Link to="/website-analytics">Web Analytics</Link> tools, I know how to help businesses make data-driven decisions that optimize their Digital Marketing strategies. From tracking website performance to developing targeted Ad Campaigns, I work closely with my clients to create custom solutions that meet their specific needs and objectives. With a focus on continuous improvement and optimization, I am committed to helping businesses achieve their Digital Marketing goals and stay ahead of the competition. If you're looking for a partner who can help you make the most of your online presence through effective Web Analytics and Google Ads strategies, look no further.</p>
</div>
</Fade>
<div className="col-sm-6 col-xl-5 text-end order-xl-2 order-1">
	<img src="images/eric.png" width="70%" alt="Eric Case" />
</div>

</div>

</div>
</section>

<section>
    	<div className="container-fluid mt-90">
     
     <div className="mx_wdth2 sec_3 mt-90 text-center">
				<h2>Certificates<span style={{ color: '#223464' }}></span> </h2>
</div>
<Zoom>
        <div className="row mt-5">
          <div className="col-lg-6">            
              <img src="images/crt1.webp" width="100%" alt="Google Analytics Individual Qualification" />
            </div>
          
          <div className="col-lg-6">
            <img src="images/crt2.webp" width="100%" alt="Google Ads Search Certification" />
          </div>
        </div>
        </Zoom>
        <Zoom>
 <div className="row">
          <div className="col-lg-6">            
              <img src="images/crt3.webp" width="100%" alt="Google Ads Display Certification" />
            </div>
          
          <div className="col-lg-6">
            <img src="images/crt4.webp" width="100%" alt="Google Ads Shopping Certification" />
          </div>
        </div>
        </Zoom>
      </div>   
</section>

{/* <section>
<div className="ab_sec5 sec4 text-white text-center mt-90">
<div className="container-fluid">
<h2 className="">Business Achievements</h2>
<p className="">We deal with a variety of clients. Both domestic and foreign customers use it. We serve over 2000 clients with our services.</p>
</div>
</div>
</section> */}

<section>
  <div className="container-fluid abt_sec6"> 
  	<div className="mx_wdth2 sec_3  mt-90 text-center">
		<p className="svr_12 pt-3">-Team Work Makes The Dream Work-</p>
				<h2>Meet  <span style={{ color: '#223464' }}>Our Team</span> </h2>
</div>
        <div className="row mt-5">
  <div className="custom-col-xl col-12 col-md-4 my-4">
    <div className="tem_1 image-box shadow text-center p-2">
      <img className="pt-3" src="images/abhi.webp" alt="Abhishek Sharma" width="70%" />
      <h5 className="pt-3">Abhishek Sharma</h5>
      <p className="">(UI Developer)</p>
    </div>
  </div>
  <div className="custom-col-xl col-12 col-md-4 my-4">
    <div className="tem_1 image-box shadow text-center p-2">
      <img className="pt-3" src="images/vikas.webp" alt="Vikas Singh" width="70%" />
      <h5 className="pt-3">Vikas Singh</h5>
      <p className="">(Web Designer)</p>
    </div>
  </div>
  <div className="custom-col-xl col-12 col-md-4 my-4">
    <div className="tem_1 image-box shadow text-center p-2">
      <img className="pt-3" src="images/hr1.png" alt="Priyanka Pathania" width="70%" />
      <h5 className="pt-3">Priyanka Pathania</h5>
      <p className="">(HR Executive)</p>
    </div>
  </div>
  <div className="custom-col-xl col-12 col-md-4 my-4">
    <div className="tem_1 image-box shadow text-center p-2">
      <img className="pt-3" src="images/aditi.webp" alt="Aditi Joshi" width="70%" />
      <h5 className="pt-3">Aditi Joshi</h5>
      <p className="">(SEO Executive)</p>
    </div>
  </div>
  <div className="custom-col-xl col-12 col-md-4 my-4">
    <div className="tem_1 image-box shadow text-center p-2">
      <img className="pt-3" src="images/ankit.webp" alt="Ankit Sharma" width="70%" />
      <h5 className="pt-3">Ankit Sharma</h5>
      <p className="">(Web Developer)</p>
    </div>
  </div>
          <div className="custom-col-xl col-12 col-md-4 my-4">
            <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/rahul.webp" alt="Rahul Trehan" width="70%" />
              <h5 className="pt-3">Rahul Trehan</h5>
              <p className="">(Graphic Designer)</p>
            </div>
          </div>
          <div className="custom-col-xl col-12 col-md-4 my-4">
            <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/sunil.webp" alt="Suneel Kumar" width="70%" />
              <h5 className="pt-3">Suneel Kumar</h5>
              <p className="">(Web Developer)</p>
            </div>
          </div>
          <div className="custom-col-xl col-12 col-md-4 my-4">
             <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/mohit.webp" alt="Mohit" width="70%" />
              <h5 className="pt-3">Mohit</h5>
              <p className="">(Web Designer)</p>
            </div>
          </div>
          <div className="custom-col-xl col-12 col-md-4 my-4">
            <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/vikesh.webp" alt="Vikesh Choudhary" width="70%" />
              <h5 className="pt-3">Vikesh Choudhary</h5>
              <p className="">(SEO Executive)</p>
            </div>
          </div>
          <div className="custom-col-xl col-12 col-md-4 my-4">
             <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/dipika.webp" alt="Dipika Rani" width="70%" />
              <h5 className="pt-3">Dipika Rani</h5>
              <p className="">(Graphic Designer)</p>
            </div>
          </div>
          <div className="custom-col-xl col-12 col-md-4 my-4">
             <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/aditibd.png" alt="Aditi Sharma" width="70%" />
              <h5 className="pt-3">Aditi Sharma</h5>
              <p className="">(Business Developer)</p>
            </div>
          </div>
          <div className="custom-col-xl col-12 col-md-4 my-4">
             <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/sakshi.png" alt="Sakshi Kumari" width="70%" />
              <h5 className="pt-3">Sakshi Kumari</h5>
              <p className="">(Graphic Designer)</p>
            </div>
          </div>
          <div className="custom-col-xl col-12 col-md-4 my-4">
             <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/sanant.png" alt="Sanant Saini" width="70%" />
              <h5 className="pt-3">Sanant Saini</h5>
              <p className="">(Graphic Designer)</p>
            </div>
          </div>
          <div className="custom-col-xl col-12 col-md-4 my-4">
             <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/sonal.png" alt="Sonal Thakur" width="70%" />
              <h5 className="pt-3">Sonal Thakur</h5>
              <p className="">(SEO Executive)</p>
            </div>
          </div>
          <div className="custom-col-xl col-12 col-md-4 my-4">
             <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/piyush.png" alt="Gamer" width="70%" />
              <h5 className="pt-3">Piyush Mehta</h5>
              <p className="">(SEO Executive)</p>
            </div>
          </div>
          <div className="custom-col-xl col-12 col-md-4 my-4">
             <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/mukesh.png" alt="mukesh" width="70%" />
              <h5 className="pt-3">Mukesh Kumar</h5>
              <p className="">(SEO Executive)</p>
            </div>
          </div>
          <div className="custom-col-xl nancy_1 col-12 col-md-4 my-4">
             <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/nancy.png" alt="nancy" width="70%" />
              <h5 className="pt-3">Nancy</h5>
              <p className="">(SEO Executive)</p>
            </div>
          </div>
          <div className="custom-col-xl nancy_1 col-12 col-md-4 my-4">
             <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/sahil.png" alt="sahil" width="70%" />
              <h5 className="pt-3">Sahil Saini</h5>
              <p className="">(Sr. BD Manager)</p>
            </div>
          </div>
          <div className="custom-col-xl nancy_1 col-12 col-md-4 my-4">
             <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/rajni.png" alt="Rajni" width="70%" />
              <h5 className="pt-3">Rajni kashyap</h5>
              <p className="">(BD Executive)</p>
            </div>
          </div>
          <div className="custom-col-xl nancy_1 col-12 col-md-4 my-4">
             <div className="tem_1 image-box shadow text-center p-2">
              <img className="pt-3" src="images/ettu.png" alt="Ettu" width="70%" />
              <h5 className="pt-3">Ettu Mehra</h5>
              <p className="">(SEO Executive)</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="ab_sec7 mt-90" style={{ backgroundSize : 'contain' }} aria-label='Ut back'>
<Fade up>
<div className="mx_wdth2  text-white text-center">
				<h2>Why Choose Us?</h2>
                    <p style={{ fontSize: '14px' }} className="text-center mx-4">With so many options to choose from, it can be tough to know which agency to trust. Here's why our offering is the clear choice</p>
</div>
        <div className="container-fluid row pt-4">
          <div className="col-md-4 mb-sm-0 mb-4">
            <div className="dotted-box text-white dt_2">
              <h5 className="pt-1">Complete Digital Marketing agency</h5>
              <p>Our approach involves a comprehensive analysis of your web presence, allowing us to provide solutions that address both immediate needs and long-term goals.</p>
            </div>
          </div>
          <div className="col-md-4 mb-sm-0 mb-4  text-white">
            <div className="dotted-box dt_1">
              <h5 className="pt-4">Results</h5>
              <p>We excel at delivering results and pride ourselves on our successful track record. Check out our case studies.</p>
            </div>
          </div>
          <div className="col-md-4 mb-sm-0 mb-4  text-white">
            <div className="dotted-box dt_1">
              <h5 className="pt-4">Value</h5>
              <p>Total service transparency and affordable rates. We provide a India company service at a regional rate.</p>
            </div>
          </div>
        </div>
<div className="container-fluid pt-5 row">
          <div className="col-md-4 mb-sm-0 mb-4">
            <div className="dotted-box text-white dt_2">
              <h5 className="pt-3">Small Company Service</h5>
              <p>Big enough to get the job done and small enough to care. We are passionate about your business success!</p>
            </div>
          </div>
          <div className="col-md-4 mb-sm-0 mb-4  text-white">
            <div className="dotted-box ">
              <h5 className="pt-4">Goals</h5>
              <p>A perfect website is responsive, easy to read, and has good navigation for a positive user experience.</p>
            </div>
          </div>
          <div className="col-md-4 mb-sm-0 mb-4  text-white">
            <div className="dotted-box dt_1">
              <h5 className="pt-4">Easy to Work With</h5>
              <p>We’re professional, enthusiastic and great fun to work with!</p>
            </div>
          </div>
        </div>
        </Fade>
     </section>

     <Testimonial />

        </div>
    );
}

export default About;