import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet'; 
import { Fade } from 'react-reveal';
function Portfolio(){
           // Function to handle tab link click event and set active tab
function handleTabLinkClick(event) {
  event.preventDefault();

  var clickedTabId = event.target.getAttribute('href');
  setActiveTab(clickedTabId);
}

// Function to set the active tab and update tab links and contents
function setActiveTab(tabName) {
  var tabLinks = document.querySelectorAll('.nav-link');
  tabLinks.forEach(function (link) {
    link.classList.remove('active');
  });

  this.classList.add('active');

  var tabContents = document.querySelectorAll('.tab-pane');
  tabContents.forEach(function (content) {
    content.classList.remove('active');
  });

  document.querySelector(tabName).classList.add('active');
}

// Function to toggle tab visibility on button click
function toggleTab(event, tabName) {
  var tabcontent = document.getElementById(tabName);

  if (tabcontent) {
    if (tabcontent.style.display === 'block') {
      tabcontent.style.display = 'none';
      event.currentTarget.classList.remove('active');
    } else {
      tabcontent.style.display = 'block';
      event.currentTarget.classList.add('active');
    }
  }
}

// Attach click event listeners to tab links
var tablinks = document.getElementsByClassName('tablinks');
for (var i = 0; i < tablinks.length; i++) {
  tablinks[i].addEventListener('click', function (event) {
    handleTabLinkClick(event);
  });
}

    return (
       
        <div className="mainn">
          <Helmet>
        <title>
        Uniwork Technologies: A Glimpse into Our Web Portfolio</title>
<meta name='description' content='Create a stunning website with ease! Uniwork Technologies specializes in crafting tailored websites to meet your unique needs. Explore our services today.' />
      </Helmet>
        <section>
        <div className="prtfliosec_1" style={{ backgroundImage: "url('images/prtfoliobnr.webp')" }} aria-label="Portfolio"> 
         <div className="websec_1  bnrtxt_1 container-fluid prt1bnrtxt_1">
            <h1><span style={{color: '#fff'}}>Portfolio</span></h1>
         </div>
         </div>
     </section> 
     <div className="tab mobport_1">
  <button className="tablinks" onClick={(event) => toggleTab(event, 'tab1')}>All</button>
  <div id="tab1" className="tabcontent">
    <div className="tab-content">
      <div className="tab-pane fade show active">
      <div className="col-md-4">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/crush-creative.png" className="img-fluid" alt="Crush Creative" />
              <div className="btnnn"><Link to="https://www.crushcreatives.io/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/flook-img.webp" className="img-fluid" alt="Flook The Label- Portfolio ffff" />
              <div className="btnnn"><Link to="https://flookthelabel.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/incredo_web.png" className="img-fluid" alt="Incredo Portfolio" />
              <div className="btnnn"><Link to="https://www.incredo.co/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
        <div className="portfolio-item">
            <div className="alll">
            <img src="images/bonusbashers.png" className="img-fluid" alt="bonus bashers Portfolio" />
              <div className="btnnn"><Link to="https://www.bonusbashers.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
        <div className="portfolio-item">
            <div className="alll">
            <img src="images/poggers.png" className="img-fluid" alt="poggers Portfolio" />
              <div className="btnnn"><Link to="https://poggers.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
        <div className="portfolio-item">
            <div className="alll">
            <img src="images/curbside.png" className="img-fluid" alt="Curbside Portfolio" />
              <div className="btnnn"><Link to="https://www.curbsidelaundries.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
        <div className="portfolio-item">
            <div className="alll">
            <img src="images/formulla1wax.png" className="img-fluid" alt="formulla1wax Portfolio" />
              <div className="btnnn"><Link to="https://formula1wax.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
        <div className="portfolio-item">
            <div className="alll">
            <img src="images/inovative.png" className="img-fluid" alt="innovativeresearchers Portfolio" />
              <div className="btnnn"><Link to="https://innovativeresearchers.in/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
        <div className="portfolio-item">
            <div className="alll">
            <img src="images/zirux.png" className="img-fluid" alt="zirux Portfolio" />
              <div className="btnnn"><Link to="https://zirux.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
        <div className="portfolio-item">
            <div className="alll">
            <img src="images/kuber.png" className="img-fluid" alt="kuber Portfolio" />
              <div className="btnnn"><Link to="https://kuberamarkets.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
        <div className="portfolio-item">
            <div className="alll">
            <img src="images/spiritsorcing.png" className="img-fluid" alt="spiritsorcing Portfolio" />
              <div className="btnnn"><Link to="https://spirit-sourcing.de/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
         <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/nikstone.webp" className="img-fluid" alt="Niko Stones- Portfolio" />
          <div className="btnnn"><Link to="https://nikostones.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
			 <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/afterlight-img.webp" className="img-fluid" alt="Afterlight Leisure- Portfolio" />
          <div className="btnnn"><Link to="https://afterlightleisure.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
                 <div className="col-md-4">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/visicase.webp" className="img-fluid" alt="Visicase - Portfolio" />
          <div className="btnnn"><Link to="https://visicase.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/imigration.webp" className="img-fluid" alt="Swallow Immigration- Portfolio" />
          <div className="btnnn"><Link to="https://swallowimmigration.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/rlh.webp" className="img-fluid" alt="Raloha- Portfolio" />
          <div className="btnnn"><Link to="https://raloha.com/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
             <div className="col-md-4">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/shehzad.webp" className="img-fluid" alt="Shehzad Fahad- Portfolio" />
          <div className="btnnn"><Link to="https://shehzadafahad.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/bub.webp" className="img-fluid" alt="Bubmats- Portfolio" />
          <div className="btnnn"><Link to="https://bubmats.com/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/nun.webp" className="img-fluid" alt="Noona social- Portfolio" />
          <div className="btnnn"><Link to="https://noonasocial.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
             <div className="col-md-4">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/mad.webp" className="img-fluid" alt="Nomad Internet- Portfolio" />
          <div className="btnnn"><Link to="https://nomadinternet.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/icon.webp" className="img-fluid" alt="Iconz Business Solutions- Portfolio" />
          <div className="btnnn"><Link to="https://iconzbiz.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/shiprx-img.webp" className="img-fluid" alt="Shiprx- Portfolio" />
          <div className="btnnn"><Link to="https://shiprx.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
             <div className="col-md-4">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/rug.webp" className="img-fluid" alt="Rug Porter- Portfolio" />
          <div className="btnnn"><Link to="https://rugporter.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/battle.webp" className="img-fluid" alt="Wattle & Wax- Portfolio" />
          <div className="btnnn"><Link to="https://wattleandwax.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/real.webp" className="img-fluid" alt="The Elevate Difference- Portfolio" />
          <div className="btnnn"><Link to="https://prmbc.ca/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
             <div className="col-md-4">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/fish.webp" className="img-fluid" alt="Fisher- Portfolio" />
          <div className="btnnn"><Link to="https://fishercoating.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/sound.webp" className="img-fluid" alt="Esound- Portfolio" />
          <div className="btnnn"><Link href="https://esound.ca/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/soma.webp" className="img-fluid" alt="Soma- Portfolio" />
          <div className="btnnn"><Link to="https://www.somawomen.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
             <div className="col-md-4">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/wmn.webp" className="img-fluid" alt="WCCD- Portfolio" />
          <div className="btnnn"><Link href="https://www.womenofcolorcd.org/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/point.webp" className="img-fluid" alt="Point Karting- Portfolio" />
          <div className="btnnn"><Link to="https://pointkarting.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/guyna.webp" className="img-fluid" alt="Blossoms of Guyana- Portfolio" />
          <div className="btnnn"><Link to="https://blossomsofguyana.org/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
             <div className="col-md-4">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/ldra.webp" className="img-fluid" alt="Lidera Consulting Ltd.- Portfolio" />
          <div className="btnnn"><Link to="https://lidera.ca/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/sea.webp" className="img-fluid" alt="Sea To Sky- Portfolio" />
          <div className="btnnn"><Link to="https://www.seatoskymassage.ca/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/bill.webp" className="img-fluid" alt="Billies House- Portfolio" />
          <div className="btnnn"><Link to="https://billieshouse.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
             <div className="col-md-4">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/beligrant.webp" className="img-fluid" alt="Belinda Grant Real Estate- Portfolio" />
          <div className="btnnn"><Link to="https://belindagrant.ca/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/sustain.webp" className="img-fluid" alt="Centre For Sustainability- Portfolio" />
          <div className="btnnn"><Link to="https://centreforsustainability.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
            <div className="col-md-4">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/epic.webp" className="img-fluid" alt="Epic- Portfolio" />
          <div className="btnnn"><Link to="https://www.epiccrunch.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
          </div>
      </div>
    </div>
  </div>
  <button className="tablinks" onClick={(event) => toggleTab(event, 'tab2')}>Coaching</button>
     <div id="tab2" className="tabcontent">
     <div className="row mt-0 mt-sm-5">
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/ldra.webp" className="img-fluid" alt="Lidera Consulting Ltd.- Portfolio" />
          <div className="btnnn"><Link to="https://lidera.ca/" target="_blank" rel="noopener"> Quick View</Link></div>          
         </div>
        </div>
        </div>
      </div>
</div>
<button className="tablinks" onClick={(event) => toggleTab(event, 'tab3')}>Construction Websites</button>
     <div id="tab3" className="tabcontent">
     <div className="row mt-0 mt-sm-5">
             <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/fish.webp" className="img-fluid" alt="Fisher- Portfolio" />
          <div className="btnnn"><Link to="https://fishercoating.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
     </div>
    </div>
	<button className="tablinks" onClick={(event) => toggleTab(event, 'tab4')}>E-commerce Website</button>
    <div id="tab4" className="tabcontent">
      <div className="row mt-0 mt-sm-5">
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/crush-creative.png" className="img-fluid" alt="crush-creative" />
          <div className="btnnn"><Link to="https://www.crushcreatives.io/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
             <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/wmn.webp" className="img-fluid" alt="WCCD- Portfolio" />
          <div className="btnnn"><Link to="https://www.womenofcolorcd.org/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/incredo_web.png" className="img-fluid" alt="incredo Portfolio" />
          <div className="btnnn"><Link to="https://www.incredo.co/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
      <div className="portfolio-item">
            <div className="alll">
            <img src="images/bonusbashers.png" className="img-fluid" alt="bonus bashers Portfolio" />
              <div className="btnnn"><Link to="https://www.bonusbashers.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
       <div className="portfolio-item">
            <div className="alll">
            <img src="images/poggers.png" className="img-fluid" alt="poggers Portfolio" />
              <div className="btnnn"><Link to="https://poggers.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
      <div className="portfolio-item">
            <div className="alll">
            <img src="images/curbside.png" className="img-fluid" alt="Curbside Portfolio" />
              <div className="btnnn"><Link to="https://www.curbsidelaundries.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
      <div className="portfolio-item">
            <div className="alll">
            <img src="images/formulla1wax.png" className="img-fluid" alt="formulla1wax Portfolio" />
              <div className="btnnn"><Link to="https://formula1wax.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
      <div className="portfolio-item">
            <div className="alll">
            <img src="images/inovative.png" className="img-fluid" alt="innovativeresearchers Portfolio" />
              <div className="btnnn"><Link to="https://innovativeresearchers.in/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
      <div className="portfolio-item">
            <div className="alll">
            <img src="images/zirux.png" className="img-fluid" alt="zirux Portfolio" />
              <div className="btnnn"><Link to="https://zirux.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
      <div className="portfolio-item">
            <div className="alll">
            <img src="images/kuber.png" className="img-fluid" alt="kuber Portfolio" />
              <div className="btnnn"><Link to="https://kuberamarkets.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
      <div className="portfolio-item">
            <div className="alll">
            <img src="images/spiritsorcing.png" className="img-fluid" alt="spiritsorcing Portfolio" />
              <div className="btnnn"><Link to="https://spirit-sourcing.de/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/bill.webp" className="img-fluid" alt="Billies House- Portfolio" />
          <div className="btnnn"><Link to="https://billieshouse.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/battle.webp" className="img-fluid" alt="Wattle & Wax- Portfolio" />
          <div className="btnnn"><Link to="https://wattleandwax.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/crush-creative.png" className="img-fluid" alt="crush-creative" />
              <div className="btnnn"><Link to="https://www.crushcreatives.io/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
             <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/flook-img.webp" className="img-fluid" alt="Flook The Label- Portfolio" />
              <div className="btnnn"><Link to="https://flookthelabel.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/nikstone.webp" className="img-fluid" alt="Niko Stones- Portfolio" />
              <div className="btnnn"><Link to="https://nikostones.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/afterlight-img.webp" className="img-fluid" alt="Afterlight Leisure- Portfolio" />
              <div className="btnnn"><Link to="https://afterlightleisure.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/rug.webp" className="img-fluid" alt="Rug Porter- Portfolio" />
          <div className="btnnn"><Link to="https://rugporter.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/bub.webp" className="img-fluid" alt="Bubmats- Portfolio" />
          <div className="btnnn"><Link to="https://bubmats.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/sound.webp" className="img-fluid" alt="Esound- Portfolio" />
          <div className="btnnn"><Link to="https://esound.ca/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/epic.webp" className="img-fluid" alt="Epic- Portfolio" />
          <div className="btnnn"><Link to="https://www.epiccrunch.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
          </div>
      </div>
	  <button className="tablinks" onClick={(event) => toggleTab(event, 'tab5')}>Health & Beauty Websites</button>
  <div id="tab5" className="tabcontent">
    <div className="row mt-0 mt-sm-5">
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
          <div className="alll">
            <img src="images/sea.webp" className="img-fluid" alt="Sea To Sky- Portfolio" />
            <div className="btnnn"><Link to="https://www.seatoskymassage.ca/" target="_blank" rel="noopener"> Quick View</Link></div>
          </div>
        </div>
      </div>    
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
          <div className="alll">
            <img src="images/soma.webp" className="img-fluid" alt="Soma- Portfolio" />
            <div className="btnnn"><Link to="https://www.somawomen.com/" target="_blank" rel="noopener"> Quick View</Link></div>
          </div>
        </div>
      </div>  
    </div>
  </div>
  <button className="tablinks" onClick={(event) => toggleTab(event, 'tab6')}>Home Services</button>
  <div id="tab6" className="tabcontent">
    <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
      <div className="portfolio-item">
        <div className="alll">
          <img src="images/fish.webp" className="img-fluid" alt="Fisher- Portfolio" />
          <div className="btnnn"><Link to="https://fishercoating.com/" target="_blank" rel="noopener"> Quick View</Link></div>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
      <div className="portfolio-item">
        <div className="alll">
          <img src="images/real.webp" className="img-fluid" alt="The Elevate Difference- Portfolio" />
          <div className="btnnn"><Link to="https://prmbc.ca/" target="_blank" rel="noopener"> Quick View</Link></div>
        </div>
      </div>
    </div>
  </div>
   <button className="tablinks" onClick={(event) => toggleTab(event, 'tab7')}>Personal Services</button>
     <div id="tab7" className="tabcontent">
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/shehzad.webp" className="img-fluid" alt="Shehzad Fahad- Portfolio" />
          <div className="btnnn"><Link to="https://shehzadafahad.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
   </div>
     <button className="tablinks" onClick={(event) => toggleTab(event, 'tab8')}>Real Estate</button>
    <div id="tab8" className="tabcontent">
    <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/real.webp" className="img-fluid" alt="The Elevate Difference- Portfolio" />
          <div className="btnnn"><Link to="https://prmbc.ca/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
              </div>
</div>

<section id="Portfolio">
<div className="container-fluid pb-20">
    <ul className="nav cstmprt_1 nav-pills justify-content-between pb-90 mb-0" role="tablist">
      <li className="nav-item" role="presentation">
        <Link className="nav-link active" data-bs-toggle="pill" to="#st1" aria-selected="false" role="tab">All</Link>
      </li>
      <li className="nav-item" role="presentation">
        <Link className="nav-link" data-bs-toggle="pill" to="#st2" aria-selected="false" role="tab">Coaching</Link>
      </li>
      <li className="nav-item" role="presentation">
        <Link className="nav-link btnprt_2" data-bs-toggle="pill" to="#st3" aria-selected="true" role="tab">Construction<br /> Websites</Link>
      </li>
      <li className="nav-item" role="presentation">
        <Link className="nav-link btnprt_2" data-bs-toggle="pill" to="#st4" aria-selected="false" role="tab">E-commerce<br /> Website</Link>
      </li>
      <li className="nav-item" role="presentation">
        <Link className="nav-link btnprt_2" data-bs-toggle="pill" to="#st5" aria-selected="false" role="tab">Health &amp; Beauty Websites</Link>
      </li>
      <li className="nav-item" role="presentation">
        <Link className="nav-link" data-bs-toggle="pill" to="#st6" aria-selected="false" role="tab">Home Services</Link>
      </li>
      <li className="nav-item" role="presentation">
        <Link className="nav-link btnprt_3" data-bs-toggle="pill" to="#st7" aria-selected="false" role="tab">Personal Services</Link>
      </li>
      <li className="nav-item" role="presentation">
        <Link className="nav-link" data-bs-toggle="pill" to="#st8" aria-selected="false" role="tab">Real Estate</Link>
      </li>
    </ul>
  </div>
  <div className="tab-content pb-90">
    <div id="st1" className="tab-pane container-fluid show active">
      <div className="row">
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/crush-creative.png" className="img-fluid" alt="crush-creative" />
              <div className="btnnn"><Link to="https://www.crushcreatives.io/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/flook-img.webp" className="img-fluid" alt="Flook The Label- Portfolio" />
              <div className="btnnn"><Link to="https://flookthelabel.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
            <img src="images/incredo_web.png" className="img-fluid" alt="Incredo Portfolio" />
              <div className="btnnn"><Link to="https://www.incredo.co/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
            <img src="images/bonusbashers.png" className="img-fluid" alt="bonus bashers Portfolio" />
              <div className="btnnn"><Link to="https://www.bonusbashers.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
            <img src="images/poggers.png" className="img-fluid" alt="poggers Portfolio" />
              <div className="btnnn"><Link to="https://poggers.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
            <img src="images/curbside.png" className="img-fluid" alt="Curbside Portfolio" />
              <div className="btnnn"><Link to="https://www.curbsidelaundries.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
            <img src="images/formulla1wax.png" className="img-fluid" alt="formulla1wax Portfolio" />
              <div className="btnnn"><Link to="https://formula1wax.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
            <img src="images/inovative.png" className="img-fluid" alt="innovativeresearchers Portfolio" />
              <div className="btnnn"><Link to="https://innovativeresearchers.in/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
            <img src="images/zirux.png" className="img-fluid" alt="zirux Portfolio" />
              <div className="btnnn"><Link to="https://zirux.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
            <img src="images/kuber.png" className="img-fluid" alt="kuber Portfolio" />
              <div className="btnnn"><Link to="https://kuberamarkets.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
            <img src="images/spiritsorcing.png" className="img-fluid" alt="spiritsorcing Portfolio" />
              <div className="btnnn"><Link to="https://spirit-sourcing.de/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/nikstone.webp" className="img-fluid" alt="Niko Stones- Portfolio" />
              <div className="btnnn"><Link to="https://nikostones.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/afterlight-img.webp" className="img-fluid" alt="Afterlight Leisure- Portfolio" />
              <div className="btnnn"><Link to="https://afterlightleisure.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
          <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/visicase.webp" className="img-fluid" alt="Visicase - Portfolio" />
          <div className="btnnn"><Link to="https://visicase.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/imigration.webp" className="img-fluid" alt="Swallow Immigration- Portfolio" />
          <div className="btnnn"><Link to="https://swallowimmigration.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/rlh.webp" className="img-fluid" alt="Raloha- Portfolio" />
          <div className="btnnn"><Link to="https://raloha.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/shehzad.webp" className="img-fluid" alt="Shehzad Fahad- Portfolio" />
          <div className="btnnn"><Link to="https://shehzadafahad.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/bub.webp" className="img-fluid" alt="Bubmats- Portfolio" />
          <div className="btnnn"><Link to="https://bubmats.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/nun.webp" className="img-fluid" alt="Noona social- Portfolio" />
          <div className="btnnn"><Link to="https://noonasocial.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/mad.webp" className="img-fluid" alt="Nomad Internet- Portfolio" />
          <div className="btnnn"><Link to="https://nomadinternet.com/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
      </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/icon.webp" className="img-fluid" alt="Iconz Business Solutions- Portfolio" />
          <div className="btnnn"><Link to="https://iconzbiz.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/shiprx-img.webp" className="img-fluid" alt="Shiprx- Portfolio" />
          <div className="btnnn"><Link to="https://shiprx.com/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/rug.webp" className="img-fluid" alt="Rug Porter- Portfolio" />
          <div className="btnnn"><Link to="https://rugporter.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
      </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/battle.webp" className="img-fluid" alt="Wattle & Wax- Portfolio" />
          <div className="btnnn"><Link to="https://wattleandwax.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/real.webp" className="img-fluid" alt="The Elevate Difference- Portfolio" />
          <div className="btnnn"><Link to="https://prmbc.ca/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
              <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/fish.webp" className="img-fluid" alt="Fisher- Portfolio" />
          <div className="btnnn"><Link to="https://fishercoating.com/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
      </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/sound.webp" className="img-fluid" alt="Esound- Portfolio" />
          <div className="btnnn"><Link to="https://esound.ca/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/soma.webp" className="img-fluid" alt="Soma- Portfolio" />
          <div className="btnnn"><Link to="https://www.somawomen.com/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/wmn.webp" className="img-fluid" alt="WCCD- Portfolio" />
          <div className="btnnn"><Link to="https://www.womenofcolorcd.org/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
      </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/point.webp" className="img-fluid" alt="Point Karting- Portfolio" />
          <div className="btnnn"><Link to="https://pointkarting.com/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/guyna.webp" className="img-fluid" alt="Blossoms of Guyana- Portfolio" />
          <div className="btnnn"><Link to="https://blossomsofguyana.org/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/ldra.webp" className="img-fluid" alt="Lidera Consulting Ltd.- Portfolio" />
          <div className="btnnn"><Link to="https://lidera.ca/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
      </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/sea.webp" className="img-fluid" alt="Sea To Sky- Portfolio" />
          <div className="btnnn"><Link to="https://www.seatoskymassage.ca/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/bill.webp" className="img-fluid" alt="Billies House- Portfolio" />
          <div className="btnnn"><Link to="https://billieshouse.com/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
             <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/beligrant.webp" className="img-fluid" alt="Belinda Grant Real Estate- Portfolio" />
          <div className="btnnn"><Link to="https://belindagrant.ca/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/sustain.webp" className="img-fluid" alt="Centre For Sustainability- Portfolio" />
          <div className="btnnn"><Link to="https://centreforsustainability.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/epic.webp" className="img-fluid" alt="Epic- Portfolio" />
          <div className="btnnn"><Link to="https://www.epiccrunch.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>  
      </div>
    </div>
    <div id="st2" className="tab-pane container-fluid">
      <div className="row">
       <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/ldra.webp" className="img-fluid" alt="Lidera Consulting Ltd.- Portfolio" />
          <div className="btnnn"><Link to="https://lidera.ca/" target="_blank" rel="noopener"> Quick View</Link></div>          
         </div>
        </div>
      </div>
      </div>
    </div>
  
  <div id="st3" className="tab-pane container-fluid">
    <div className="row">
    <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/fish.webp" className="img-fluid" alt="Fisher- Portfolio" />
          <div className="btnnn"><Link to="https://fishercoating.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
    </div>
  </div>
  
  <div id="st4" className="tab-pane container-fluid">
    <div className="row">
    <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/crush-creative.png" className="img-fluid" alt="crush-creative" />
          <div className="btnnn"><Link to="https://www.crushcreatives.io/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
    <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/wmn.webp" className="img-fluid" alt="WCCD- Portfolio" />
          <div className="btnnn"><Link to="https://www.womenofcolorcd.org/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/bill.webp" className="img-fluid" alt="Billies House- Portfolio" />
          <div className="btnnn"><Link to="https://billieshouse.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/battle.webp" className="img-fluid" alt="Wattle & Wax- Portfolio" />
          <div className="btnnn"><Link to="https://wattleandwax.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
             <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/flook-img.webp" className="img-fluid" alt="Flook The Label- Portfolio" />
              <div className="btnnn"><Link to="https://flookthelabel.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/nikstone.webp" className="img-fluid" alt="Niko Stones- Portfolio" />
              <div className="btnnn"><Link to="https://nikostones.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
          <div className="portfolio-item">
            <div className="alll">
              <img src="images/afterlight-img.webp" className="img-fluid" alt="Afterlight Leisure- Portfolio" />
              <div className="btnnn"><Link to="https://afterlightleisure.com/" target="_blank" rel="noopener"> Quick View</Link></div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/rug.webp" className="img-fluid" alt="Rug Porter- Portfolio" />
          <div className="btnnn"><Link to="https://rugporter.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/bub.webp" className="img-fluid" alt="Bubmats- Portfolio" />
          <div className="btnnn"><Link to="https://bubmats.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/sound.webp" className="img-fluid" alt="Esound- Portfolio" />
          <div className="btnnn"><Link to="https://esound.ca/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/epic.webp" className="img-fluid" alt="Epic- Portfolio" />
          <div className="btnnn"><Link to="https://www.epiccrunch.com.au/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
            </div>
          </div>
  </div>  
  <div id="st5" className="tab-pane container-fluid mb-md-3">
    <div className="row">
      <div className="col-lg-4 col-md-6 my-xl-4">
              <div className="portfolio-item">
         <div className="alll">
           <img src="images/sea.webp" className="img-fluid" alt="Sea To Sky- Portfolio" />
          <div className="btnnn"><Link to="https://www.seatoskymassage.ca/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>    
            <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/soma.webp" className="img-fluid" alt="Soma- Portfolio" />
          <div className="btnnn"><Link to="https://www.somawomen.com/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>  
    </div>
  </div>
  
  <div id="st6" className="tab-pane container-fluid">
    <div className="row">
    <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/fish.webp" className="img-fluid" alt="Fisher- Portfolio" />
          <div className="btnnn"><Link to="https://fishercoating.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/real.webp" className="img-fluid" alt="The Elevate Difference- Portfolio" />
          <div className="btnnn"><Link to="https://prmbc.ca/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
  </div>
  </div>
  <div id="st7" className="tab-pane container-fluid">
    <div className="row">
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
        <div className="portfolio-item">
         <div className="alll">
           <img src="images/shehzad.webp" className="img-fluid" alt="Shehzad Fahad- Portfolio" />
          <div className="btnnn"><Link to="https://shehzadafahad.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
    </div>
  </div>
  
  <div id="st8" className="tab-pane container-fluid">
    <div className="row">
      <div className="col-lg-4 col-md-6 my-xl-4 mb-md-3">
              <div className="portfolio-item">
         <div className="alll">
          <img src="images/real.webp" className="img-fluid" alt="The Elevate Difference- Portfolio" />
          <div className="btnnn"><Link to="https://prmbc.ca/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
            </div>
    </div>
  </div>
</div>
</section>
</div>

);
 }
export default Portfolio;